import axios from 'axios'
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
} from '../constants/cartConstants'

if (process.env.REACT_APP_ENV == "prod") {
  axios.defaults.baseURL = 'https://difatours.aynnaka.app'
} else {
  axios.defaults.baseURL = 'http://127.0.0.1:8080'
  // axios.defaults.baseURL = 'http://127.0.0.1:8000'
}
// axios.defaults.baseURL = 'https://difatours.aynnaka.app'


export const addToCart = (id, fqte, fdiscount, twqte, twdiscount, thqte, thdiscount, dateBook, items) => async (dispatch, getState) => {
  console.log(items);
  const { data } = await axios.get(`/api/products/${id}`)


  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: data._id,
      name: data.name,
      description: data.description,
      image: data.images[0].image,
      type: data.type,
      price: data.price,
      countInStock: data.countInStock,
      fqte,
      fdiscount,
      twqte,
      twdiscount,
      thqte,
      thdiscount,
      dateBook,
      priceEuro: data.price_euro,
      is_promo: data.is_promo,
      promotion: data.promotion,
      itemProd:data.items.length!==0? items.length===0?[data.items[0]._id]:items:[],
      items:data.items,
    },
  })
 
  localStorage.setItem('cartItems1', JSON.stringify(getState().cart.cartItems))
}

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  })

  localStorage.setItem('cartItems1', JSON.stringify(getState().cart.cartItems))
}

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  })
  localStorage.setItem('shippingAddress', JSON.stringify(data))
}

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  })

  localStorage.setItem('paymentMethod', JSON.stringify(data))
}
