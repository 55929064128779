import React, { useEffect, useState } from 'react'
import Partners from '../components/Partners'
import { useTranslation } from 'react-i18next';

function AboutUs() {
    const { t } = useTranslation();




    return (
        <div className=''  >
            <div className="pt-32 mt-16">

                <div className='text-center'>
                    <h1 className='text-5xl'>
                        {t("who_are_we")}
                    </h1>
                </div>

            </div>

            <div className='max-w-screen-lg px-4 mx-auto md:flex items-center pt-12'>

                <div className='md:w-1/2'>
                    <img src="/im1.png" alt="" />
                </div>

                <div className='md:w-1/2'>
                    <p>
                        {t("global_tourism_receptive_agency_in_morocco")}
                    </p>
                    <p className='pt-2'>
                        {t("difatours_maroc_launches_outgoing_service_tes_clubs_vacances")}
                    </p>
                </div>

            </div>
            

            <div className='py-10 max-w-screen-xl mx-auto'>
                <div className='pb-8 text-center max-w-2xl mx-auto'>
                    <h1 className='text-2xl font-bold'>
                        {t("our_profile_professional_commitments")}
                    </h1>
                    <p className='pt-2'>
                        {t("sa_vocation_ses_choix_sa_charte_professionnelle_sa_force_ses_recherches_de_propositions_nouvelles_ses_alliances_internationales_ses_developpements")}
                    </p>
                </div>



                <div className='grid grid-cols-1 md:grid-cols-3 gap-8 px-4 md:gap-8'>
                    <div className='px-4 py-8 bg-white rounded-md bg-opacity-10 shadow-md'>
                        <div className='w-20 mx-auto pb-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-20 text-primary w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                            </svg>
                        </div>
                        <div className='text-xl font-bold'>
                            {t("sa_force_ses_recherches_de_propositions_nouvelles")}
                        </div>

                        <div className='pt-3 text-sm'>
                            {t("nous_activites_touristiques")}
                        </div>

                    </div>
                    <div className='px-4 py-8 bg-white rounded-md bg-opacity-10 shadow-md'>
                        <div className='w-20 mx-auto pb-3'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                            </svg>
                        </div>
                        <div className='text-xl font-bold '>
                            {t("its_international_alliances_its_developments")}
                        </div>

                        <div className='pt-3 text-sm'>
                            {t("parfaite_maitrise_du_terrain_partenaire_privilegie")}
                        </div>

                    </div>
                    <div className='px-4 py-8 bg-white rounded-md bg-opacity-10 shadow-md'>
                        <div className='w-20 mx-auto pb-3'>

                            <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                <path strokeLinecap="round" strokeLinejoin="round" d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5" />
                            </svg>
                        </div>
                        <div className='text-xl font-bold'>
                            {t("sa_vocation_ses_choix_sa_charte_professionnelle")}
                        </div>

                        <div className='pt-3 text-sm'>
                            {t("difatours_maitrise_qualite_expertise_enjeu_developpement")}
                        </div>

                    </div>
                </div>

            </div>
            <div className='py-10'>
                <Partners />
            </div>

        </div>
    )
}

export default AboutUs