import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StatsOrder } from '../../actions/orderActions'
import { StatsProducts } from '../../actions/productActions'
import Layout from './Layout'
import Calendar from "react-calendar";
import '../../style/Calendar.css'
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    LineChart,
    Line,
    Bar,
    BarChart,
} from "recharts";

function Dashboard({ history, match }) {

    const dispatch = useDispatch()

    const { loading, error, all, active } = useSelector(state => state.productStats)
    const orderState = useSelector(state => state.orderStats)


    const { all: all_order_stats, paid: paid_order_stats, loading: loading_order_stats, total: total_order_stats, dataorder: dataorder_order_stats, mergeddata: mergeddata_order_stats } = orderState


    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin
    useEffect(() => {
        if (userInfo && userInfo.isAdmin) {
            dispatch(StatsProducts())
            dispatch(StatsOrder())
        } else {
            history.push('/login')
        }
    }, [dispatch])


    return (
        <Layout >
            <div className="px-2 w-full">


                <h1 className='text-xl pb-1 '>Tableau de bord </h1>
                <hr />
                {/*  */}
                <div className="grid md:grid-cols-3 grid-cols-2 gap-2 lg:gap-8 mt-10">
                    <CardItem
                        title={"Produit Vendu"}
                        icon={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                                />
                            </svg>
                        }
                        number={paid_order_stats}
                    />
                    <CardItem
                        title={"Revenu"}
                        icon={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                                />
                            </svg>
                        }
                        number={`${total_order_stats + " MAD "}`}
                    />
                    <CardItem
                        title={"Produits actifs"}
                        icon={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
                                />
                            </svg>
                        }
                        number={loading ? 'loading ...' : error ?
                            error :
                            active
                        }
                    />

                </div>
                {/*  */}
                <div className="py-4">
                    <h1>Stats</h1>
                    <div className="pt-3 grid md:grid-cols-5 gap-3">
                        <div className="md:col-span-3 bg-white shadow-md rounded-xl">
                            <div className=" p-2">
                                {/* <div className="py-2">Choisir une date :</div> */}
                                {/* <div className="pb-3">
                                    <select
                                        onChange={(e) => setFilterDate(e.target.value)}
                                        name="date"
                                        id="date"
                                        className="bg-transparent border rounded-md outline-none p-2"
                                    >
                                        <option value="today">Aujourd'hui </option>

                                        <option value="this_month">Ce Mois </option>
                                        <option value="last_month">Mois dernier </option>
                                        <option value="all_time">Tout le temps </option>
                                    </select>
                                </div> */}


                                <BarChart width={440} height={300} data={mergeddata_order_stats}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />

                                    <Bar dataKey="sales" fill="#39C3D1" />

                                </BarChart>
                            </div>
                        </div>
                        <div className=" md:col-span-2">
                            <div className="grid grid-cols-2 md:grid-cols-1 gap-3 ">
                                <div className="">
                                    <Calendar
                                        locale="fr"
                                        showNavigation={false}
                                        view="month"
                                        // tileClassName={colorRange}
                                        className={"  p-2 w-full border-none rounded-md "}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* ProductOverview */}
                <div>
                    <div className="pb-3 pt-6">
                        <h1>Product Overview</h1>
                    </div>
                    <div class="flex flex-col">
                        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                                <div class="overflow-hidden">
                                    <table class="min-w-full">
                                        <thead class="bg-white border-b">
                                            <tr>

                                                <th
                                                    scope="col"
                                                    class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                                >
                                                    Product
                                                </th>
                                                <th
                                                    scope="col"
                                                    class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                                >
                                                    Quantity
                                                </th>
                                                <th
                                                    scope="col"
                                                    class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                                >
                                                    Quantity Sold
                                                </th>

                                                <th
                                                    scope="col"
                                                    class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                                                >
                                                    Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataorder_order_stats.map((item, id) => (
                                                <tr
                                                    key={item._id}
                                                    class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                                                >

                                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {item.product.name}
                                                    </td>
                                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {item.product.countInStock + item.order.qty}
                                                    </td>
                                                    <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {item.order.qty}
                                                    </td>

                                                    <td class="text-sm relative text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                                        {item.order.amount + " MAD "}
                                                    </td>
                                                </tr>
                                            ))}

                                            <tr className="h-32"></tr>

                                            {/* <div className=''>
                                        <Paginate page={page} pages={pages} />

                                    </div> */}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>

    )
}

export default Dashboard


const CardItem = ({ title, number, icon }) => {
    return (
        <div className="border card-bg text-white rounded-xl px-4 py-3">
            <div className="flex items-center gap-3 text-xs">
                {" "}
                {icon} {title}{" "}
            </div>
            <div className="py-2 text-2xl font-extrabold">{number}</div>
        </div>
    );
};