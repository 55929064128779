import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { listUsers, deleteUser } from '../../actions/userActions'
import { Link, useHistory } from 'react-router-dom'
import SideBar from '../../components/SideBar'
import getFormatedPrice from '../../utils/getFormatedPrice'
import useOnClickOutside from '../../hooks/useOnClickOutside'


const UserItem = ({ user }) => {
  const ref = useRef(null)

  const history = useHistory()

  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside(ref, () => setIsOpen(false))


  const deleteHandler = (id) => (ev) => {
    if (window.confirm('Are you sure')) {
      dispatch(deleteUser(id))
    }
  }



  return (
    <div className='flex justify-between mb-2 w-full py-2 px-4 border rounded-sm'>
      <div className=''>
        <div className='w-20 float-left'>
          <img src={"/user-icon.jpeg"} className="w-full object-cover object-center" alt="" />
        </div>
        <div className='pl-2 float-right'>
          <div>
            {user._id}
          </div>
          <div>
            {user.email}
          </div>
          <div className='text-sm opacity-70'>
            {user.isAdmin ? "Admin" : "Not Admin"}
          </div>

        </div>
      </div>

      <div className='cursor-pointer relative'>
        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setIsOpen(!isOpen)} className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>

        <div ref={ref} className={`p-2 ${isOpen ? 'block' : 'hidden'} absolute bg-white shadow-md border z-50 right-0`}>

          <button onClick={() => history.push(`/admin/user/${user._id}/edit/`)} className='whitespace-nowrap hover:bg-gray-50 py-1 px-4'>
            Edit User
          </button>
          <button className='whitespace-nowrap disabled:bg-gray-100 mb-2 hover:bg-gray-50 py-1 px-4'>
            {user.is_active ? 'Disable' : 'Activate'}  User
          </button>

          <hr />
          <button onClick={deleteHandler(user._id)} className='whitespace-nowrap text-red-600 hover:bg-gray-50 py-1 px-4'>
            Delete User
          </button>
        </div>
      </div>


    </div>
  )
}

const UserListScreen = ({ history }) => {
  const dispatch = useDispatch()

  const userList = useSelector((state) => state.userList)
  const { loading, error, users } = userList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userDelete = useSelector((state) => state.userDelete)
  const { success: successDelete } = userDelete

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listUsers())
    } else {
      history.push('/login')
    }
  }, [dispatch, history, successDelete, userInfo])


  return (
    <>

      <div className="admin-screen">
        <div className="flex ">
          <div className='w-1/5 '>
            <SideBar />
          </div>



          <div className="px-2 md:w-3/5 w-full">
            <h1 className='text-xl pb-1 '>Users </h1>
            <hr />
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (

              <div className='pt-3'>
                {users.map((user) => <UserItem user={user} />)}
              </div>
            )}
          </div>
        </div>
      </div>


    </>
  )
}

export default UserListScreen
