import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { deleteOrder, listOrders } from '../../actions/orderActions'
import SideBar from '../../components/SideBar'
import { Link, useHistory } from 'react-router-dom'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import getFormatedPrice from '../../utils/getFormatedPrice'
import Paginate from '../../components/Paginate'
import Layout from './Layout'
import axios from 'axios'


const OrderItemlast = ({ order }) => {
  const ref = useRef(null)

  const history = useHistory()

  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside(ref, () => setIsOpen(false))


  const deleteHandler = (id) => (ev) => {

    if (window.confirm("Are you sure !")) {
      dispatch(deleteOrder(id))
    }

  }



  return (
    <div className='flex justify-between mb-2 w-full py-2 px-4 border rounded-sm'>
      <div className=''>
        {/* <div className='w-20 float-left'>
          <img src={product?.images[0]?.image} alt="" />
        </div> */}
        <div className='pl-2 float-right'>
          <div className='text-xl pb-2'>
            {order.shippingAddress && order.shippingAddress.lastName} {order.shippingAddress && order.shippingAddress.firstName}

          </div>
          <div className='pb-1'>
            Total : {getFormatedPrice(parseFloat(order.totalPrice))}
          </div>
          <div className='flex justify-between flex-grow'>
            <div className='opacity-70 pr-4'>
              Commande au : :{order?.createdAt?.substring(0, 10)}
            </div>
            <div className='opacity-70'>
              {order.isPaid ?
                <div className='text-green-600'>
                  |  Payé à {order.isPaid && order.paidAt.substring(0, 10)}
                </div> :
                <div className='text-red-600'>
                  |  Non payé
                </div>
              }
            </div>

          </div>


        </div>
      </div>

      <div className='cursor-pointer relative'>
        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setIsOpen(!isOpen)} className="h-6 w-6 fill-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>

        <div ref={ref} className={`p-2 ${isOpen ? 'block' : 'hidden'} absolute bg-white shadow-md border z-50 right-0`}>
          <button onClick={() => history.push(`/order/${order._id}/`)} className='whitespace-nowrap  hover:bg-gray-50 py-2 px-4 block'>
            Détails de la commande
          </button>

          <a href={`mailto:${order.shippingAddress.email}`} className='whitespace-nowrap  hover:bg-gray-50 py-2 px-4 block'>
            Envoyer un courriel
          </a>


          <hr />
          <button onClick={deleteHandler(order._id)} className='whitespace-nowrap  hover:bg-gray-50 py-2 px-4'>
            Supprimer la commande
          </button>
        </div>
      </div>


    </div>
  )
}


const OrderItem = ({ order }) => {
  const ref = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  useOnClickOutside(ref, () => setIsOpen(false));

  const deleteHandler = (id) => (ev) => {
    dispatch(deleteOrder(id));
  };

  return (
    <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
      <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
        <a className="text-blue-600" href={`/order/${order._id}/`}>
          {order._id.substring(0, 8)}...
        </a>
      </td>
      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
        {order.createdAt?.substring(0, 10)}
      </td>
      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
        {order.shippingAddress && order.shippingAddress.lastName} {order.shippingAddress && order.shippingAddress.firstName}
      </td>
      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
        {order.isPaid ?
          <div className='text-green-600'>
            Payé
          </div> :
          <div className='text-red-600'>
            Non payé
          </div>
        }
      </td>

      <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
        {/* {order.orderItems.reduce(function (a, b) {
          return a + parseFloat(b.price) * b.qty;
        }, 0)}{" "} */}
        {order.totalPrice}
        {order.currency_info === "DHS" ? 'DHS' : "€"}
      </td>
      <td class="text-sm relative text-gray-900 font-light px-6 py-4 whitespace-nowrap">
        {/* °°° */}
        <div className='cursor-pointer relative'>
          <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setIsOpen(!isOpen)} className="h-6 w-6 fill-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>

          <div ref={ref} className={`p-2 ${isOpen ? 'block' : 'hidden'} absolute bg-white shadow-md border z-50 right-0`}>
            <button onClick={() => history.push(`/order/${order._id}/`)} className='whitespace-nowrap  hover:bg-gray-50 py-2 px-4 block'>
              Détails de la commande
            </button>

            <a href={`mailto:${order.shippingAddress.email}`} className='whitespace-nowrap  hover:bg-gray-50 py-2 px-4 block'>
              Envoyer un courriel
            </a>

            <button onClick={deleteHandler(order._id)} className='whitespace-nowrap  hover:bg-gray-50 py-2 px-4 block'>
              Supprimer la commande
            </button>
          </div>
        </div>
      </td>
    </tr>
  );
};

const OrderListScreen = ({ history, match }) => {

  const dispatch = useDispatch()
  const url = new URLSearchParams(window.location.search)

  // const pageNumber = match.params.pageNumber || 1
  const pageNumber = url.get('page') || 1

  const orderList = useSelector((state) => state.orderList)
  const { loading, pages, page, error, orders, count } = orderList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const order_choices = [
    { id: 1, name: "All", value: "All" },
    { id: 2, name: "Paid", value: "True" },
    { id: 3, name: "Not Paid", value: "False" },

  ]

  const [orderStatus, setOrderStatus] = useState()

  useEffect(() => {

    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders(orderStatus, pageNumber))
    } else {
      history.push('/login')
    }
  }, [dispatch, history, pageNumber, userInfo, orderStatus])


  const ExportData = () => {
    try {
      axios
        .get(`api/orders/export/`, {
          responseType: "blob",
        })
        .then((response) => {
          let url = window.URL.createObjectURL(new Blob([response.data]));
          let link = document.createElement("a");
          link.href = url;
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = today.getFullYear();

          link.setAttribute(
            "download",
            `orders--${dd}-${mm}-${yyyy}-${today.getHours()}${today.getMinutes()}.csv`
          );
          document.body.appendChild(link);
          link.click();
        });
    } catch (error) { }
  };

  return (
    <>
      <Layout>
        {/* new */}



        {loading ? (
          "loading ..."
        ) : error ? (
          error
        ) : (
          <div className="w-full ">
            <div className="w-full pb-8 flex justify-between items-center ">
              <h1 className="text-xl ">Commandes</h1>

              <div className="flex items-center gap-x-3 ">
                <div className="flex items-center gap-3">
                  {order_choices.map((or_ch) => (
                    <div
                      className={` cursor-pointer  border rounded-full px-3 ${or_ch.value == orderStatus
                        ? "bg-primary text-white"
                        : "bg-white"
                        }  `}
                      key={or_ch.id}
                    >
                      <div onClick={(e) => setOrderStatus(or_ch.value)}>
                        {or_ch.name}
                      </div>
                    </div>
                  ))}
                </div>

                {/*  */}
                <button
                  onClick={ExportData}
                  className="bg-secondary flex items-center rounded-full px-4 py-2 text-white "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                    />
                  </svg>
                  <span className="pl-2">Download</span>
                </button>
              </div>
            </div>
            {/* orders */}

            <div class="flex flex-col bg-white rounded-md mt-4 ">
              <div class=" sm:-mx-6 lg:-mx-8 ">
                <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="overflow-hiddens">
                    <table class="min-w-full ">
                      <thead class="bg-white border-b">
                        <tr>
                          <th
                            scope="col"
                            class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            #
                          </th>
                          <th
                            scope="col"
                            class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            Client
                          </th>
                          <th
                            scope="col"
                            class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            Statut
                          </th>
                          <th
                            scope="col"
                            class="text-sm whitespace-nowrap font-medium text-gray-900 px-6 py-4 text-left"
                          >
                            Prix Total
                          </th>
                          <th
                            scope="col"
                            class="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                          ></th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <Loader />
                        ) : error ? (
                          <Message variant="danger">{error}</Message>
                        ) : (
                          <>
                            {orders.map((order, id) => (
                              // <p>{order._id}</p>
                              <OrderItem order={order} key={id} />
                            ))}
                          </>
                        )}

                        <tr className="h-16"></tr>
                      </tbody>
                    </table>
                    <Paginate section={"orderlist"} pages={pages} page={page} />
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
          </div>

        )}


        {/* last */}
        {/* <div className="flex">


          <div className="px-2 md:w-4/5 w-full">
            <div className='flex  justify-between p-2   items-center align-items-center'>
              <h1 className='text-xl '> Commandes</h1>
              <hr />

             

            </div>
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <div className='w-full'>
                {orders.map((order) => <OrderItem order={order} key={order._id} />)}

                <div className='pt-3'>
                  <Paginate type={"orderlist"} pages={pages} page={page} isAdmin={true} />
                </div>

              </div>

            )}

          </div>

          <div className='w-1/5 md:block hidden pl-4 px-2'>
            <div className='py-2'>
              <h1 className='text-xl border-b '>Filters</h1>
            </div>

            <div className='pt-2'>
              {order_choices.map(or_ch => <div key={or_ch.id} className='py-1'>
                <input type="radio" onChange={(e) => setOrderStatus(e.target.value)} name="status" id="status" value={or_ch.id} /> {or_ch.name}
              </div>)}

            </div>
          </div>
        </div> */}
      </Layout>
    </>
  )
}

export default OrderListScreen




