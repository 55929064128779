import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { login, logout } from '../actions/userActions'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
if (process.env.REACT_APP_ENV == "prod") {
  // axios.defaults.baseURL = 'https://difatours.aynnaka.app'
  axios.defaults.baseURL = 'https://difatours.aynnaka.app'
} else {
  axios.defaults.baseURL = 'http://127.0.0.1:8080'
  // axios.defaults.baseURL = 'http://127.0.0.1:8000'
}


const LoginScreen = ({ location, history }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  const redirect = location.search ? location.search.split('=')[1] : '/admin/'

  useEffect(() => {
    if (userInfo) {
      if (userInfo.isAdmin) {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
        try {
          axios.get(`/api/users/check-login/`, config).then((data) => {
            history.push(redirect)
          }).catch((er) => {
            dispatch(logout())
          })
        } catch (error) {
          dispatch(logout())
        }

      } else {
        history.push("/")
      }
    }
  }, [history, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }

  return (

    <div className="max-w-screen-sm  mx-auto pt-32  mt-16">
      <h1>{t("sign_in")}</h1>
      {error && <Message variant='danger'>{t(error)}</Message>}
      {loading && <Loader />}
      <form className="w-full p-3 " onSubmit={submitHandler}>
        <div controlId='email'>
          <div>{t("email_address")}</div>
          <div className="border my-2">
            <input
              className="outline-none w-full px-2 py-2 bg-transparent "
              type='email'
              placeholder={t("enter_email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>

        </div>

        <div controlId='password'>
          <div>{t("password")}</div>
          <div className="border my-2">
            <input
              type='password'
              className="outline-none w-full px-2 py-2 bg-transparent"
              placeholder={t("enter_password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
          </div>
        </div>

        <button className="py-2 px-4  flex text-center text-gray-50 bg-black" type='submit' >
          {t("sign_in")}
        </button>
      </form>

      <div className='py-3'>
        <div>
          <Link to={redirect ? `/register?redirect=${redirect}` : '/register'}>
            {t("not_have_account_create_new")}
          </Link>
        </div>
      </div>

    </div>
  )
}

export default LoginScreen
