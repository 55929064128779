import React, { useEffect, useState } from 'react'
import SideBar from '../../components/SideBar'
import { useSelector } from 'react-redux';
import axios from 'axios'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
if (process.env.REACT_APP_ENV == "prod") {
  // axios.defaults.baseURL = 'https://difatours.aynnaka.app'
  axios.defaults.baseURL = 'https://difatours.aynnaka.app'
} else {
  axios.defaults.baseURL = 'http://127.0.0.1:8080'
  // axios.defaults.baseURL = 'http://127.0.0.1:8000'
}

function Layout(props) {
  const [isOpen, setIsOpen] = useState(false);

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  useEffect(async () => {
    const userInfoFromStorage = localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : null
    if (userInfoFromStorage != null) {


      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfoFromStorage.token}`,
        },
      }
      try {
        axios.get(`/api/users/check-login/`, config).then((data) => {

        }).catch((er) => {
          console.log(er);
          window.location.replace("/login");
        })
      } catch (error) {

      }


    } else {
      window.location.replace("/login");
    }
  }, [])

  return (
    <div dir='ltr' className="min-h-screen bg-blue-600 p-4">
      <div className="flex">
        <div className="">
          <div className="md:block block text-white ">
            <SideBar />
          </div>
          {/* 
          <div
            className={`absolute left-0 top-0 z-50 ${isOpen ? "block" : "hidden"
              }`}
          >
            <SideBar setIsOpen={setIsOpen} />
          </div> */}
        </div>

        <div className="p-4 rounded-xl flex-1 bg-[#F5F5F5]  h-screen overflow-y-scroll   w-full">
          <ToastContainer />
          {/* <button
            onClick={() => setIsOpen(!isOpen)}
            className="absolute md:hidden block left-0 top-0 bg-primary p-2 rounded-md text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button> */}
          {/* {isOpen && (
            <div
              onClick={() => setIsOpen(false)}
              className="w-screen h-screen bg-gray-200 bg-opacity-80  fixed top-0 right-0 left-0 bottom-0"
            ></div>
          )} */}
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default Layout