import React from 'react'

const FormContainer = ({ children }) => {
  return (
    <div>
      <div className='justify-content-md-center'>
        <div >
          {children}
        </div>
      </div>
    </div>
  )
}

export default FormContainer
