import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from "axios"
// import Welcome from '../components/Welcome'
import ContactUs from '../components/ContactUs'
import Video from '../components/Video'
import { useTranslation } from 'react-i18next'
// import Hero from '../components/Hero'


if (process.env.REACT_APP_ENV === "prod") {
  axios.defaults.baseURL = 'https://difatours.aynnaka.app'
} else {
  axios.defaults.baseURL = 'http://127.0.0.1:8080'
  // axios.defaults.baseURL = 'http://127.0.0.1:8000'
}

const Services = () => {
  const { t } = useTranslation();
  return (
    <div className='py-20 rounded-xl px-4 ' id='services'>
      <div className='flex pb-4 justify-center'>
        <h1 className='text-4xl text-center font-bold'>{t("our_services")}</h1>
      </div>
      <div className='grid grid-cols-1 md:grid-cols-3 gap-8 '>
        <div className='bg-white p-4 rounded-md shadow-md '>
          <div className='w-32 mx-auto'>
            <img src="/icons/ic_airline.svg" alt="" />
          </div>
          <h1 className='font-bold text-xl uppercase py-2'>{t("partner_advising")}</h1>
          <h2 className='font-bold'>
            {t("assist_in_setting_up_tourist_programs")}
          </h2>
          <p className='text-xs pt-3'>
            {t("accompanying_them_notably_in_the_selection_of_multiple_suppliers_and_negotiating_for_them_at_the_best_price")}
          </p>
        </div>
        <div className='bg-white p-4 rounded-md shadow-md'>
          <div className='w-32 mx-auto'>
            <img src="/icons/ic_travel.guide.svg" alt="" />
          </div>
          <h1 className='font-bold text-xl uppercase py-2'>{t("ensuring_the_operational")}</h1>
          <h2 className='text- font-bold'>{t("through_the_successful_completion_of_ordered_services")}</h2>
          <p className='text-xs pt-3'>
            {t("ensuring_an_optimal_and_continuous_quality_of_service")}
          </p>
        </div>
        <div className='bg-white p-4 rounded-md shadow-md'>
          <div className='w-32 mx-auto'>
            <img src="/icons/ic_festival.svg" alt="" />
          </div>
          <h1 className='font-bold text-xl uppercase py-2'>{t("operational_units")}</h1>
          <h2 className='text- font-bold'>{t("providing_efficient_operational_units")}</h2>
          <p className='text-xs pt-3'>
            {t("thanks_to_its_selected_and_trained_collaborators_with_expertise_in_the_sector_and_profession_constantly_solicited_for_field_and_internal_training")}
          </p>
        </div>
      </div>
    </div>
  )
}

const Hero = () => {
  return (
    <div className='bg-no-repeat mt-24'>
      <Video src={'./videos/video-dron.mp4'} poster={'./videos/poster.png'} />

      {/* <div className='absolute left-5 md:left-10  md:top-1/2 top-4/5 transform -translate-y-1/2 max-w-md bg-white shadow-md p-4'>
             
              <div className='pl-10'>
                <p class="pb-2 text-2xl font-bold pt-4 opacity-80">AGENCE DE VOYAGE &amp; RECEPTIVE</p>
                <p class="opacity-75 pr-8">Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt hic laudantium consectetur, atque ?</p>
              </div>
             
              <div className='absolute top-0 right-0 '>
                <img src="/logo_icon.png" className='w-20 h-20' alt="" />
              </div>
            </div> */}

    </div>


  )
}

const Presentation = () => {
  const { i18n, t } = useTranslation();
  return (
    <div className='bg-cover  relative bg-no-repeat bg-center' style={{ backgroundImage: 'url(/presentation.jpg)' }}>
      <div className='w-full h-full  absolute z-0 bg-opacity-90 bg-white'>

      </div>
      <div className='px-4  max-w-screen-xl mx-auto py-10 relative container '>
        <h1 className='text-4xl font-bold text-center uppercase '>{t("difa_tours_morocco")}</h1>
        <h2 className='text-center pt-1 mt-5 pb-2'>
          {t("travel_receptive_agency")}
        </h2>
        <div className='md:flex  items-center mt-4'>
          <div className='hidden md:block md:w-1/5 relative'>
            <img src="/im1.png" className='' alt="" srcset="" />
            {/* <img src="/2.jpeg" className='w-2/3 ' alt="" srcset="" /> */}
          </div>
          <div className='md:w-2/3 '>
            <p>
              {t("our_expertise")}
            </p>
            <p className='mt-3'>
              {t("our_renowned_strengths")}
            </p>

            <div className='flex gap-x-10 pt-10 md:justify-center justify-between'>
              <div className='hidden md:block'>
                <div className='flex items-center'>
                  <img src="/icons/1.png" className='md:w-18 w-12' alt="" srcset="" />
                  <span className='mx-2'>
                    {t('knowing_how_to_organize')}
                  </span>
                </div>
              </div>
              <div className='flex items-center'>
                <img src="/icons/2.png" className='md:w-18 w-12' alt="" srcset="" />
                <span className='mx-2'>{t("managing_skills")}</span>
              </div>
              <div className='flex items-center'>
                <img src="/icons/3.png" className='md:w-18 w-12' alt="" srcset="" />
                <span className='mx-2'>{t("proposing")}</span>
              </div>
            </div>
            <div className='block md:hidden '>
                <div className='flex items-center pt-5'>
                  <img src="/icons/1.png" className='md:w-18 w-12' alt="" srcset="" />
                  <span className='mx-2'>
                    {t('knowing_how_to_organize')}
                  </span>
                </div>
              </div>

          </div>

        </div>



      </div>


    </div>




  )
}


function HomeScreen({ history }) {

  const [categories, setCategories] = useState([])


  useEffect(() => {
    axios.get('/api/categories/').then(res => {

      setCategories(res.data.categories)
    }).catch(err => {
      setCategories([])
    })

  }, [])


  return (
    <div className=''>
      <Hero />

      <Presentation />
      <div className='px-8 py-4 max-w-screen-xl mx-auto  '>
        <Services />
      </div>

      <div id='contacts'></div>

      <ContactUs />
    </div>
  )
}

export default HomeScreen