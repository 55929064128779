import React from 'react'
import Meta from '../components/Meta'

function SucessPayment() {
  return (
        <div className="pt-32  min-h-screen w-full flex flex-col justify-between items-center ">
            <Meta title={"Success payment"} />
            <div className='pt-20 flex flex-col items-center text-center'>

                <div className='text-green-600 '>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-20 w-20" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
                <div>
                    <h1 className='text-2xl font-bold py-4 text-green-600'>Payment successful </h1>
                    <h3 className='text-xl font-semibold'>Thank you for your purchace</h3>
                    <p className='text-md font-semibold'>
                        See you in DAKHLA !
                    </p>
                </div>

            </div>
        
        </div>
  )
}

export default SucessPayment