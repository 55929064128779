import React from 'react'
import { useTranslation } from 'react-i18next'

function ContactUs() {
    const { t } = useTranslation()
    return (
        <div className='py-10 max-w-screen-xl mx-auto' >
            <div className='md:min-h-[60vh] text-black flex md:flex-row flex-col gap-4 justify-center items-center '>
                <div className='md:w-1/2  p-3 md:p-4 rounded-lg shadow-md '>
                    <div className='max-w-lg mx-auto'>
                        <div className='text-center'>
                            <div className='  '>
                                <h1 className='text-3xl font-bold'>{t("contact_us")}</h1>
                                {/* <p className='text-sm py-4'>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Harum, natus doloribus earum rerum eaque.</p> */}

                                <div className='grid pt-4 grid-cols-2 gap-2'>
                                    <div className='flex flex-col items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fill-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                                        </svg>
                                        <p className='pt-2 text-sm opacity-70'>
                                            2, Rue Ibn Aîcha Marrkaech
                                            Maroc, 40 000
                                        </p>
                                    </div>

                                    <div className='flex flex-col items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fill-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
                                        </svg>
                                        <p className='pt-2 text-sm opacity-70'>
                                            contact@difatours.ma
                                        </p>
                                    </div>


                                    <div className='flex flex-col items-center' dir='ltr'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fill-primary " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={0}>
                                            <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                        </svg>

                                        <p className='pt-2 text-sm opacity-70' >
                                            (+212) 5-24 43 04 18
                                        </p>
                                    </div>

                                    <div className='flex flex-col items-center' dir='ltr'>
                                        <a href='https://api.whatsapp.com/send?text=Welcome to Diffatours.' className='flex flex-col  items-center'>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fill-primary " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={0}>
                                                <path
                                                    d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                                            </svg>
                                            <p className='pt-2 text-sm opacity-70'>
                                                (+212) 5-24 43 04 18
                                            </p>
                                        </a>
                                    </div>

                                </div>






                            </div>
                        </div>

                        <div className='pt-3'>
                            <form action="#" method="post">
                                <div className='grid grid-cols-2 gap-2'>
                                    <div className=''>
                                        <div className='border rounded-md'>
                                            <input type="text" className='p-2 outline-none w-full bg-transparent' placeholder={t("name")} />
                                        </div>
                                    </div>
                                    <div className=''>
                                        <div className='border rounded-md'>
                                            <input type="text" className='p-2 outline-none w-full bg-transparent' placeholder={t("email")} />
                                        </div>
                                    </div>

                                </div>
                                <div className='pt-2'>
                                    <div className='border rounded-md'>
                                        <textarea name="message" placeholder={t("your_message")} className='p-2 w-full outline-none bg-transparent' id="message" rows="5"></textarea>
                                    </div>
                                </div>
                                <div className='pt-2 float-right'>
                                    <button className='p-2 bg-primary '>
                                        {t("send")}
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div className='md:w-1/2  p-3 md:p-4'>
                    <iframe className='md:w-[600px] w-[420px] h-[450px]' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13587.462846765147!2d-8.011044!3d31.637527!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x233ee5d4ee9079a1!2sDifa+Tour!5e0!3m2!1sfr!2s!4v1462524015968" width="600" height="450" frameborder="0" allowfullscreen=""></iframe>
                </div>


            </div>

        </div>
    )
}

export default ContactUs