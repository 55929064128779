import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { register } from '../actions/userActions'
import { useTranslation } from 'react-i18next'

const RegisterScreen = ({ location, history }) => {
  const { t } = useTranslation();
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState(null)

  const dispatch = useDispatch()

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, userInfo } = userRegister

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      history.push(redirect)
    }
  }, [history, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    if (password !== confirmPassword) {
      setMessage(t('passwords_do_not_match'))
    } else {
      dispatch(register(name, email, password))
    }
  }

  return (
    <div className="max-w-screen-sm  mx-auto pt-32 mt-16">
      <div className="bg-white p-4  ">
        <h1>{t("sign_up")}</h1>

        {message && <Message >{t(message)}</Message>}
        {error && <Message >{t(error)}</Message>}
        {loading && <Loader />}
        <form onSubmit={submitHandler}>
          <div className="mt-2" controlId='name'>
            <label>{t("name")}</label>
            <input
              className="py-2 px-2 w-full  outline-none bg-transparent border"
              type='text'
              placeholder={t("enter_name")}
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>

          <div className="mt-2" controlId='email'>
            <label>{t("email_address")}</label>
            <input
              type='email'
              className="py-2 px-2 w-full  outline-none bg-transparent border"
              placeholder={t("enter_email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>

          <div className="mt-2" controlId='password'>
            <label>{t("password")}</label>
            <input
              type='password'
              className="py-2 px-2 w-full  outline-none bg-transparent border"
              placeholder={t("enter_password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
          </div>

          <div className="mt-2" controlId='confirmPassword'>
            <label>{t("confirm_password")}</label>
            <input
              type='password'
              className="py-2 px-2 w-full  outline-none bg-transparent border"
              placeholder={t("confirm_password")}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            ></input>
          </div>

          <button className="py-2 px-3 mt-2 bg-black text-gray-50" type='submit' variant='primary'>
            {t("sign_up")}
          </button>
        </form>

        <div className='py-3'>
          <div>
            <Link className="text-blue-700 " to={redirect ? `/login?redirect=${redirect}` : '/login'}>
              {t("have_account_login")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisterScreen
