import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listSubscribers } from '../../actions/userActions'
import SideBar from '../../components/SideBar'
import Layout from './Layout'


const SubscriberItem = ({ sub }) => {
  return (
    <div className='border mb-2 p-4 rounded-md'>
      <div className='text-xl'>
        {sub.email}
      </div>
      <div className='text-sm pt-1'>
        {sub.createdAt}
      </div>
    </div>
  )
}

function SubsriberScreen({ history }) {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const dispatch = useDispatch()
  const { data, loading, error } = useSelector(state => state.listSubscribers)

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listSubscribers())

    } else {
      history.push('/login')
    }

  }, [dispatch, history, userInfo])

  return (
    <div >
      <Layout>
        <div className="flex ">
          <div className="px-2 md:w-4/5 relative w-full">
            <h1 className='text-xl pb-1 '>Subscribers </h1>
            <hr />
            <div className='pt-3'>
              <div className='p-4'>
                {
                  loading ?
                    "loading ..." :
                    error ?
                      <div>
                        {error}
                      </div>
                      :
                      data?.map((sub) => <SubscriberItem key={sub._id} sub={sub} />)
                }
              </div>
            </div>


          </div>
        </div>
      </Layout>
    </div>
  )
}

export default SubsriberScreen