import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';


export const languages = [
    {
        code: 'fr',
        name: 'french',
        dir: 'ltr',
        country_code: 'fr',
    },
    {
        code: 'en',
        name: 'english',
        dir: 'ltr',
        country_code: 'gb',
    },
    {
        code: 'it',
        name: 'italian',
        dir: 'ltr',
        country_code: 'it',
    },
    // {
    //     code: 'ar',
    //     name: 'arabic',
    //     dir: 'rtl',
    //     country_code: 'sa',
    // },
    {
        code: 'nl',
        name: 'dutch',
        dir: 'ltr',
        country_code: 'nl',
    },
]

export const currencies = [
    {
        code: 'Euro',
        name: 'euro',
        price: "€"
    },
    {
        code: 'DHS',
        name: 'dhs',
        price: "dhs"
    },
]




i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        supportedLngs: ["en", "fr", "ar", "it", "nl"],
        fallbackLng: "en",
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        },
        detection: {
            order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
            caches: ['cookie'],
        },
        backend: {
            loadPath: '/lang/{{lng}}/translation.json',
        },
        // react: {
        //     useSuspense: false,
        // },
    });

export default i18n;