import React from 'react'

function Cgv() {
  return (
    <div className='pt-32 p-4 max-w-screen-md mx-auto'>
      
    </div>
  )
}

export default Cgv