import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import Paginate from '../../components/Paginate'
import {
  deleteProduct,
  createProduct,
  disableProduct,
  listAdminProducts,
} from '../../actions/productActions'
import { PRODUCT_CREATE_RESET } from '../../constants/productConstants'
import SideBar from '../../components/SideBar'
import getFormatedPrice from '../../utils/getFormatedPrice'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { useHistory } from 'react-router-dom'
import Layout from './Layout'



const Item = ({ product }) => {
  const ref = useRef(null)

  const history = useHistory()

  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside(ref, () => setIsOpen(false))

  const deleteHandler = (id) => (ev) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce produit')) {
      dispatch(deleteProduct(id))
    }
  }

  const handleDisableProduct = (id, isActive) => (ev) => {

    dispatch(disableProduct(id, isActive))

  }

  const getImageFirst = (images)=>{
    var check = false;
    var image = null;
    for (let index = 0; index < images.length; index++) {
        const element = images[index];
        if(element.isfirst){
            image=element;
            check=true;
        }
    }
    if(!check){
        image=images[0]
    }
    return image
}

  return (
    <div className='flex justify-between mb-2 w-full py-2 px-4 rounded-xl shadow-md border '>
      <div className=''>
        <div className='w-28 h-28 float-left mr-3'>
          <img src={`${process.env.REACT_APP_ENV === "prod" ? 'https://difatours.aynnaka.app' : 'http://127.0.0.1:8080'}${getImageFirst(product?.images)?.image}`} className="rounded-md w-full object-cover h-full" alt="" />
        </div>
        <div className='pl-2 float-right'>
          <div className='text-lg'>
            {product.name}
          </div>

          <div className="flex items-center">
            <div>
              <span
                className={`text-sm cursor-pointer text-gray-300`}>
                ({parseFloat(product.rating).toFixed(1)})
              </span>
              {[1, 2, 3, 4, 5].map((star) => (
                <span
                  key={star}
                  className={`text-sm cursor-pointer ${star <= product.rating ? 'text-yellow-400' : 'text-gray-300'
                    }`}
                >
                  ★
                </span>
              ))}
            </div>
          </div>
          <div className='font-bold'>
            {product.items.length!==0?getFormatedPrice(parseFloat(product.items[0]?.price)): getFormatedPrice(parseFloat(product.price))}
          </div>


          <div className="text-sm py-1 opacity-70">
            {product.is_active ? (
              <span className="px-1 rounded-md bg-green-600 text-white">
                Actif
              </span>
            ) : (
              <span className="px-1 rounded-md text-white bg-red-600">
                Not Active
              </span>
            )}
          </div>

        </div>
      </div>

      <div className='cursor-pointer relative'>
        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => setIsOpen(!isOpen)} className="h-6 w-6 fill-primary " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
          <path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>

        <div ref={ref} className={`p - 2 ${isOpen ? 'block' : 'hidden'} absolute bg-white shadow-md border z-50 right-0`}>

          <button onClick={() => history.push(`/admin/product/${product._id}/edit/`)} className='whitespace-nowrap hover:bg-gray-50 py-1 px-4 w-full text-left'>
            Modifier le Produit
          </button>
          <button onClick={handleDisableProduct(product._id, !product.is_active)} className='whitespace-nowrap disabled:bg-gray-100  hover:bg-gray-50 py-1 px-4 w-full text-left'>
            {product.is_active ? 'Désactiver' : 'Activer'} le Produit
          </button>
          {
            product.reviews.length !== 0 ?
              <button onClick={() => history.push(`/admin/product/${product._id}/reviews/`)} className='whitespace-nowrap disabled:bg-gray-100 mb-2 hover:bg-gray-50 py-1 px-4 w-full text-left' >
                Reviews
              </button> : null

          }
          <hr />
          <button onClick={deleteHandler(product._id)} className='whitespace-nowrap text-red-600 hover:bg-gray-50 py-1 px-4'>
            Supprimer le Produit
          </button>
        </div>
      </div>


    </div>
  )
}

const ProductListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1


  const [showModalActions, setShowModalActions] = useState(false)

  const dispatch = useDispatch()

  const productList = useSelector((state) => state.productList)
  const { loading, error, products, page, pages } = productList

  const productDelete = useSelector((state) => state.productDelete)
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete

  const productCreate = useSelector((state) => state.productCreate)
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct
  } = productCreate

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET })

    if (!userInfo || !userInfo.isAdmin) {
      history.push('/login')
    }

    if (successCreate) {
      history.push(`/admin/product/${createdProduct._id}/edit`)
    } else {
      dispatch(listAdminProducts("", "", pageNumber))
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdProduct,
    pageNumber,
  ])



  const createProductHandler = () => {
    dispatch(createProduct())
  }

  const showActions = () => {
    setShowModalActions(!showModalActions)
  }

  return (
    <Layout >
      <div className="">


        <div className="px-2  w-full">

          <div className='pb-4 flex items-center justify-between'>
            <div className='text-xl font-bold'>
              Produits
            </div>
            <a href='/admin/product/add' className='bg-black  rounded-full p-3 px-6  text-white'>
              Ajouter un nouveau produit
            </a>
            {/* <button onClick={createProductHandler} className='bg-black  rounded-full p-3 px-6  text-white'>
            </button> */}

          </div>

          {loadingDelete && <Loader />}
          {errorDelete && <Message variant='danger'>{errorDelete}</Message>}
          {loadingCreate && <Loader />}
          {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'>{error}</Message>
          ) : (
            <>
              {products.map((product) => (
                <Item key={product._id} product={product} />
              ))}

              <Paginate type={"productlist"} pages={pages} page={page} isAdmin={true} />
            </>
          )}
        </div>

        

      </div>

    </Layout>
  )
}

export default ProductListScreen
