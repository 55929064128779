export const couponReducer = (
    state = { discount:0 },
    action
  ) => {
    switch (action.type) {
      case "APPLY_COUPON":
        return action.payload
      default:
        return state
    }
  }