import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import ShippingScreen from './screens/ShippingScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import UserListScreen from './screens/admin/UserListScreen'
import UserEditScreen from './screens/admin/UserEditScreen'
import ProductListScreen from './screens/admin/ProductListScreen'
import OrderListScreen from './screens/admin/OrderListScreen'

import ProductsListScreen from './screens/ProductsListScreen'
import ProductEditScreen from './screens/admin/ProductEditScreen'
import SucessPayment from './screens/SucessPayment'
import FailPayment from './screens/FailPayment'
import Cgv from './screens/Cgv'
import ConfigScreen from './screens/admin/ConfigScreen'
import Dashboard from './screens/admin/Dashboard'
import MessageScreen from './screens/admin/MessageScreen'
import SubsriberScreen from './screens/admin/SubsriberScreen'
import AboutUs from './screens/AboutUs'
import SearchScreen from './screens/SearchScreen'
import PickupScreen from './screens/admin/PickupScreen'
import ReviewSceen from './screens/admin/ReviewSceen'
import 'font-awesome/css/font-awesome.min.css';
import './i18n';
import Cookies from 'js-cookie'
import PackScreen from './screens/PackScreen'
import OffreScreen from './screens/OffreScreen'
import ProductAddScreen from './screens/admin/ProductAddScreen'


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return null;
}

const App = () => {

  const currentLang = Cookies.get("i18next") || 'en';
  const langCodeDir = currentLang === "ar" ? 'rtl' : "ltr";

  useEffect(() => {
    document.body.dir = langCodeDir;
    console.log(langCodeDir);
  }, [langCodeDir]);


  return (
    <div >
      
      <Router>
        <ScrollToTop />
        <Header />
        <main className='min-h-screen '>
          <>

            <Route path='/order/:id' component={OrderScreen} />
            {/* <Route path='/search' component={ProductsListScreen} /> */}
            {/* <Route path='/products' component={ProductsListScreen} /> */}
            {/* <Route path='/payment' component={PaymentScreen} /> */}
            <Route path='/payment/ok' component={SucessPayment} />
            <Route path='/payment/fail' component={FailPayment} />

            <Route path='/product/:id' component={ProductScreen} />
            {/* admin link */}
            <Route
              path='/admin'
              component={Dashboard}
              exact
            />

            <Route path='/admin/messages' component={MessageScreen} />
            <Route path='/admin/subscribers' component={SubsriberScreen} />

            <Route path='/admin/userlist' component={UserListScreen} />
            <Route path='/admin/user/:id/edit' component={UserEditScreen} />

            <Route
              path='/admin/productlist'
              component={ProductListScreen}
              exact
            />
            <Route
              path='/admin/config'
              component={ConfigScreen}
              exact
            />
            <Route
              path='/admin/productlist/:pageNumber'
              component={ProductListScreen}
              exact
            />
            <Route path='/admin/product/:id/edit' component={ProductEditScreen} />
            <Route path='/admin/product/add' component={ProductAddScreen} />
            <Route path='/admin/product/:id/reviews' component={ReviewSceen} />
            <Route path='/admin/orderlist' exact component={OrderListScreen} />
            <Route path='/admin/orderlist/:pageNumber' component={OrderListScreen} />
            <Route path='/admin/pickups/' component={PickupScreen} />

            <Switch >
              <Route path='/contact-us' component={Cgv} />
              <Route path='/about-us' component={AboutUs} />

              <Route path='/terms-and-conditions' component={Cgv} />
              <Route path='/shipping' component={ShippingScreen} />
              <Route path='/placeorder' component={PlaceOrderScreen} />
              <Route path='/login' component={LoginScreen} />
              <Route path='/register' component={RegisterScreen} />
              <Route path='/profile' component={ProfileScreen} />
              <Route path='/packe' component={PackScreen} />
              <Route path='/offre' component={OffreScreen} />
              <Route path='/cart/:id?' component={CartScreen} />
              {/* <Route path='/:type/search/:keyword' component={ProductsListScreen} exact />
              <Route
                  path='/:type/search/:keyword/page/:pageNumber'
                  component={ProductsListScreen}
                  exact
                /> 
              <Route
                  path='/:type/page/:pageNumber'
                  component={ProductsListScreen}
                  exact
                />  */}
              {/* <Route path='/products' component={ProductsListScreen} exact /> */}
              {/* <Route path='/:type' component={ProductsListScreen} exact /> */}

              {/* <Route path='/tickets/:category/:sub/' component={ProductsListScreen} exact /> */}

              <Route path='/category/:category' component={ProductsListScreen} exact />
              <Route path='/search/:search' component={SearchScreen} exact />

            </Switch>
            <Route path='/' component={HomeScreen} exact />

            {/* <Route path='/:pageNumber' component={ProductsListScreen} exact /> */}

          </>
        </main>

        <Footer />


      </Router>

    </div>
  )
}

export default App
