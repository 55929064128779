export const validate = (values) => {
    let errors = {}
    if (!values.name){
        errors["name"]="Ce champ est obligatoire"
    }
    if (!values.namefr){
        errors["namefr"]="Ce champ est obligatoire"
    }
    // if (!values.namear){
    //     errors["namear"]="Ce champ est obligatoire"
    // }
    if (!values.nameit){
        errors["nameit"]="Ce champ est obligatoire"
    }
    if (!values.nameln){
        errors["nameln"]="Ce champ est obligatoire"
    }
    if (!values.image){
        errors["image"]="Ce champ est obligatoire "
    }

    return errors;

}