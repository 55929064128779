import axios from 'axios'
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  //
  PRODUCT_LIST_PROMOTION_REQUEST,
  PRODUCT_LIST_PROMOTION_SUCCESS,
  PRODUCT_LIST_PROMOTION_FAIL,
  // 
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_TOP_REQUEST,
  PRODUCT_TOP_SUCCESS,
  PRODUCT_TOP_FAIL,
  PRODUCT_LIST_COUNT_REQUEST,
  PRODUCT_LIST_COUNT_SUCCESS,
  PRODUCT_LIST_COUNT_FAIL,
  PRODUCT_SEARCH_REQUEST,
  PRODUCT_SEARCH_SUCCESS,
  PRODUCT_SEARCH_FAIL,

  PRODUCT_REVIEWS_REQUEST,
  PRODUCT_REVIEWS_SUCCESS,
  PRODUCT_REVIEWS_FAIL,

  PRODUCT_LIST_REVIEWS_REQUEST,
  PRODUCT_LIST_REVIEWS_SUCCESS,
  PRODUCT_LIST_REVIEWS_FAIL,

  PRODUCT_DELETE_REVIEWS_REQUEST,
  PRODUCT_DELETE_REVIEWS_SUCCESS,
  PRODUCT_DELETE_REVIEWS_FAIL,

} from '../constants/productConstants'

import { logout } from './userActions'


if (process.env.REACT_APP_ENV == "prod") {
  axios.defaults.baseURL = 'https://difatours.aynnaka.app'
} else {
  axios.defaults.baseURL = 'http://127.0.0.1:8080'
  // axios.defaults.baseURL = 'http://127.0.0.1:8000'
}

export const selectImage = (imageId) => async (dispatch, getState) => {
  try {

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const data = await axios.put(`/api/products/images/${imageId}/first/`,{}, config)
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }

  }
}

export const deleteProductReviewsDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_DELETE_REVIEWS_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }


    const { data } = await axios.post(`/api/products/reviews/${id}/delete/`, {}, config)

    dispatch({
      type: PRODUCT_DELETE_REVIEWS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_DELETE_REVIEWS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listProductReviewsDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_LIST_REVIEWS_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }


    const { data } = await axios.get(`/api/products/reviews/${id}/`, config)

    dispatch({
      type: PRODUCT_LIST_REVIEWS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_REVIEWS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const StatsProducts = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_LIST_COUNT_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `/api/products/stats/`, config
    )

    dispatch({
      type: PRODUCT_LIST_COUNT_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PRODUCT_LIST_COUNT_FAIL,
      payload: message,
    })
  }
}

export const listProductsPromotion = (keyword = '', page, date = '', max = '', min = '', currencInfo = 'DHS') => async (
  dispatch
) => {
  try {
    dispatch({ type: PRODUCT_LIST_PROMOTION_REQUEST })

    const { data } = await axios.get(
      `/api/products/promotion?page=${page}&keyword=${keyword}&date=${date}&max=${max}&min=${min}&currencInfo=${currencInfo}`
    )

    dispatch({
      type: PRODUCT_LIST_PROMOTION_SUCCESS,
      payload: data,
    })

  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_PROMOTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listProducts = (category, keyword = '', page, date = '', max = '', min = '', currencInfo = 'DHS') => async (
  dispatch
) => {
  try {
    dispatch({ type: PRODUCT_LIST_REQUEST })

    const { data } = await axios.get(
      `/api/products?page=${page}&category=${category}&keyword=${keyword}&date=${date}&max=${max}&min=${min}&currencInfo=${currencInfo}`
    )

    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: data,
    })

  } catch (error) {
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const searchProducts = (category, keyword = '', page, search) => async (
  dispatch
) => {
  try {
    dispatch({ type: PRODUCT_SEARCH_REQUEST })

    const { data } = await axios.get(
      `/api/products/search?pageNumber=${page}&category=${category}&keyword=${keyword}&search=${search}`
    )

    dispatch({
      type: PRODUCT_SEARCH_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_SEARCH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listAdminProducts = (type = '', keyword = '', page) => async (
  dispatch, getState
) => {
  try {


    dispatch({ type: PRODUCT_LIST_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.get(
      `/api/products/admin/?page=${page}&keyword=${keyword}`, config
    )

    dispatch({
      type: PRODUCT_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PRODUCT_LIST_FAIL,
      payload: message,
    })
  }
}

export const listProductDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST })

    const { data } = await axios.get(`/api/products/${id}/`)

    dispatch({
      type: PRODUCT_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteProduct = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_DELETE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.delete(`/api/products/delete/${id}/`, config)

    dispatch({
      type: PRODUCT_DELETE_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PRODUCT_DELETE_FAIL,
      payload: message,
    })
  }
}

export const deleteAddon = (id, addonId) => async (dispatch, getState) => {
  try {

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const data = await axios.delete(`/api/addons/${id}/${addonId}`, config)
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }

  }
}

export const deleteImage = (id, imageId) => async (dispatch, getState) => {
  try {

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const data = await axios.delete(`/api/products/${id}/images/${imageId}/`, config)
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data })


  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }

  }
}

export const createProduct = (product, images) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const config_form = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo.token}`
      },
    }
    const formData = new FormData()

    const { data } = await axios.post(`/api/products/create/`, product, config)

    // update images 
    if (images.length > 0) {
      images.forEach(file => {
        formData.append("images", file);
      });

      await axios.post(`/api/products/${data._id}/images/`, formData, config_form)
    }


    dispatch({
      type: PRODUCT_CREATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PRODUCT_CREATE_FAIL,
      payload: message,
    })
  }
}

export const updateProduct = (product, images) => async (dispatch, getState) => {
  try {
    dispatch({
      type: PRODUCT_UPDATE_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }
    const config_form = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${userInfo.token}`
      },
    }
    const formData = new FormData()

    const { data } = await axios.put(
      `/api/products/update/${product._id}/`,
      product,
      config
    )

    // update images 
    if (images.length > 0) {
      images.forEach(file => {
        formData.append("images", file);
      });

      await axios.post(`/api/products/${product._id}/images/`, formData, config_form)

    }


    dispatch({
      type: PRODUCT_UPDATE_SUCCESS,
      payload: data,
    })
    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PRODUCT_UPDATE_FAIL,
      payload: message,
    })
  }
}

export const createProductReview = (productId, review) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: PRODUCT_CREATE_REVIEW_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios.post(`/api/products/${productId}/reviews/`, review, config)

    dispatch({
      type: PRODUCT_CREATE_REVIEW_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }
    dispatch({
      type: PRODUCT_CREATE_REVIEW_FAIL,
      payload: message,
    })
  }
}

export const listTopProducts = () => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_TOP_REQUEST })

    const { data } = await axios.get(`/api/products/top/`)

    dispatch({
      type: PRODUCT_TOP_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PRODUCT_TOP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}


export const addImageToProduct = (productId, imageData) => async (dispatch, getState) => {
  try {

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/products/${productId}/images/`, imageData, config)
    // console.log(data)

    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }

  }
}

export const addOptionToProduct = (productId, option) => async (dispatch, getState) => {
  try {

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`/api/products/${productId}/options/`, option, config)
    // console.log(data)

    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }

  }
}


//  deactive item

export const disableProduct = (productId, is_active) => async (
  dispatch,
  getState
) => {
  try {


    const {
      userLogin: { userInfo },
      productList: { products },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.put(`/api/products/${productId}/disable/`, { is_active: is_active }, config)
    console.log(products)
    const p_data = products.map(u => u._id !== data._id ? u : data)
    // console.log(p_data)
    // dispatch({ type: PRODUCT_LIST_REQUEST})

    dispatch({ type: PRODUCT_LIST_SUCCESS, payload: { products: p_data } })

  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message
    if (message === 'Not authorized, token failed') {
      dispatch(logout())
    }

  }
}


export const addImages = (productId, images) => async (dispatch, getState) => {

  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
    const formData = new FormData()

    images.forEach(file => {
      formData.append("images", file);
    });

    const { data } = await axios.post(`/api/products/${productId}/images/`, formData, config)
    console.log("images added")
    console.log(data)
  } catch (error) {
    console.log("error")
  }

}


export const addProductReview = (order, productId, review, comment, name) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: PRODUCT_REVIEWS_REQUEST,
    })

    const {
      userLogin: { userInfo },
    } = getState()

    var config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    if (userInfo) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    }

    await axios.post(`/api/products/${productId}/add-prod-reviews/`, {
      "order": order,
      "name": name,
      "rating": review,
      "comment": comment,
    }, config)

    dispatch({
      type: PRODUCT_REVIEWS_SUCCESS,
    })
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message

    dispatch({
      type: PRODUCT_REVIEWS_FAIL,
      payload: message,
    })
  }
}