import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import { saveShippingAddress } from '../actions/cartActions'
import { listPickups } from '../actions/orderActions'
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import Meta from '../components/Meta'
import { useTranslation } from 'react-i18next'

const ShippingScreen = ({ history }) => {

  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  const { t } = useTranslation()
  const hours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 21, 23]
  const mins = [0, 5, 10, 15, 20, 30, 35, 40, 45, 50, 55]

  const [selectedHour, setSelectedHour] = useState(new Date().getHours())
  const [selectedMin, setSelectedMin] = useState(new Date().getMinutes())


  const [firstName, setFirstName] = useState(shippingAddress.firstName)
  const [lastName, setLastName] = useState(shippingAddress.lastName)
  const [phone, setPhone] = useState(shippingAddress.phone)
  const [email, setEmail] = useState(shippingAddress.email)

  const [isUnder18, setIsUnder18] = useState(false)
  const [isTermsChecked, setIsTermsChecked] = useState(false)

  const [passportCin, setPassportCin] = useState(shippingAddress.passportCin)
  const [arrivalTime, setArrivalTime] = useState(shippingAddress.arrivalTime)
  const [address, setAddress] = useState(shippingAddress.address)

  const [city, setCity] = useState(shippingAddress.city)
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
  const [country, setCountry] = useState(shippingAddress.country)




  const [typeClient, setTypeClient] = useState("hotel")
  const [nameHotel, setNameHotel] = useState("")
  const [addressClient, setAddressClient] = useState("")
  const [locationxClient, setLocationxClient] = useState("")
  const [locationyClient, setLocationyClient] = useState("")


  const { loadingPick, errorPick, pickups } = useSelector((state) => state.pickupList)

  const dispatch = useDispatch()


  useEffect(async () => {
    await dispatch(listPickups()).then(() => {

    })


  }, [dispatch])

  // console.log(shippingAddress)
  const submitHandler = (e) => {
    e.preventDefault()
    let arrivalTime = selectedHour + ':' + selectedMin

    const shippingAddress = { firstName, lastName, isTermsChecked, isUnder18, phone, email, passportCin, arrivalTime, address, city, postalCode, country, address_client: typeClient === 'hotel' ? addressClient : addressClient.split("||#||")[0], type_name: nameHotel, type_client: typeClient, location_x: locationxClient, location_y: locationyClient }

    // shippingAddress.firstName.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'')
    dispatch(saveShippingAddress(shippingAddress))
    history.push('/placeorder')
  }

  // const handleChangeFirstName = (e) => {     
  //     setFirstName(e.target.value.replace(/[^a-zA-Z0-9 ]/g, ""))

  // }
  const [activeMarker, setActiveMarker] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  const handleMarkerClick = (marker) => {
    setActiveMarker(marker);
    setShowInfoWindow(true);
  };

  const handleInfoWindowClose = () => {
    setActiveMarker(null);
    setShowInfoWindow(false);
  };

  const MyMapComponent = withGoogleMap(props => (
    <GoogleMap
      defaultCenter={{ lat: parseFloat(locationxClient), lng: parseFloat(locationyClient) }}
      defaultZoom={8}
    >
      <Marker position={{ lat: parseFloat(locationxClient), lng: parseFloat(locationyClient) }} name={addressClient.split("||#||")[0]} />

    </GoogleMap>
  ));


  return (

    <div className="pt-32 lg:px-0 px-4 container max-w-screen-lg mx-auto mt-16 ">

      <FormContainer>
        <CheckoutSteps step2 />
        <h1 className="text-2xl font-medium">{t("personal_information")}</h1>
        <form onSubmit={submitHandler}>
          <div className="my-2">
            <div>{t("first_name")}</div>
            <input
              className="p-2 w-full  outline-none border "
              type='text'
              placeholder={t("enter_first_name")}
              value={firstName}
              required
              onChange={(e) => setFirstName(e.target.value.replace(/[^a-zA-Z0-9 ]/g, ""))}
            />
          </div>
          <div className="my-2">
            <div>{t("last_name")}</div>
            <input
              className="p-2 w-full  outline-none border "
              type='text'
              placeholder={t("enter_last_name")}
              value={lastName}
              required
              onChange={(e) => setLastName(e.target.value.replace(/[^a-zA-Z0-9 ]/g, ""))}
            />
          </div>


          <div className="my-2">
            <div>{t("phone")}</div>
            <input
              className="p-2 w-full  outline-none border "
              type='tel'
              pattern='[0-9]*\.?[0-9]*'
              placeholder={t("enter_phone_number")}
              value={phone}
              required
              onChange={(e) => setPhone(e.target.value.replace(/[^0-9]/g, ""))}
            />
          </div>

          <div className="my-2">
            <div>{t("email")}</div>
            <input
              className="p-2 w-full  outline-none border "
              type='email'
              placeholder={t("enter_email")}
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="my-2">
            <div>{t("passport_or_cin")}</div>
            <input
              className="p-2 w-full  outline-none border "
              type='text'
              placeholder={t("enter_passport_or_cin")}
              value={passportCin}
              required
              onChange={(e) => setPassportCin(e.target.value.replace(/[^a-zA-Z0-9]/g, ""))}
            />
          </div>
          <div className="my-2">
            <div>{t("city")}</div>
            <input
              className="p-2 w-full  outline-none border "
              type='text'
              placeholder={t("enter_city")}
              value={city}
              required
              onChange={(e) => setCity(e.target.value)}
            />
          </div>

          <div className="my-2">
            <div className='flex flex-wrap'>
              <div className='mx-3'>
                <input name='type' onChange={(e) => setTypeClient(e.target.value)} id='hotel_type' value={"hotel"} type='radio' required checked={typeClient === "hotel"} />
                <label for="hotel_type" className='mx-2' >{t("hotel")}</label>
              </div>

              <div className='mx-3'>
                <input name='type' onChange={(e) => setTypeClient(e.target.value)} id='riad_type' value={"riad"} type='radio' required checked={typeClient === "riad"} />
                <label for="riad_type" className='mx-2' >{t("riad")}</label>
              </div>
            </div>
          </div>

          {
            typeClient === "hotel" ?
              <>

                <div className="my-2">
                  <div>{t("hotel_name")}</div>
                  <input
                    className="p-2 w-full  outline-none border "
                    type='text'
                    placeholder={t("enter_hotel_name")}
                    value={nameHotel}
                    required
                    onChange={(e) => setNameHotel(e.target.value)}
                  />
                </div>
                {/*  */}
                <div className="my-2">
                  <div>{t("address")}</div>
                  <input
                    className="p-2 w-full  outline-none border "
                    type='text'
                    placeholder={t("enter_address")}
                    value={addressClient}
                    required
                    onChange={(e) => setAddressClient(e.target.value)}
                  />
                </div>
              </> : null

          }

          {
            typeClient === "riad" ?
              <>
                <div className="my-2">
                  <div>{t("pick_up_point")}</div>
                  <select value={addressClient} required onChange={(e) => {
                    setLocationxClient("");
                    setLocationyClient("");
                    if (e.target.value !== "") {
                      setLocationxClient(e.target.value.split("||#||")[1]);
                      setLocationyClient(e.target.value.split("||#||")[2]);
                    }
                    setAddressClient(e.target.value);
                  }} className="mt-2 w-full p-2 px-6 select " >
                    <option value="">{t("select_pickup_point")}</option>
                    {
                      pickups?.map((pickup) => (
                        <option value={pickup.name + "||#||" + pickup.location_x + "||#||" + pickup.location_y}>{pickup.name}</option>
                      ))
                    }

                  </select>
                </div>
                {/*  */}
                {
                  locationxClient && locationyClient ?
                    <div style={{ height: '400px', width: '100%' }}>
                      <MyMapComponent
                        containerElement={<div style={{ height: '100%' }} />}
                        mapElement={<div style={{ height: '100%' }} />}
                      />
                    </div> : null
                }


              </>
              : null
          }


          <div className="my-2 ">

            <input
              id="age"
              className="p-2  outline-none border "
              type='checkbox'
              name='isUnder18'
              value={isUnder18}
              required
              onChange={(e) => {
                setIsUnder18(e.target.checked)
              }
              }
            />
            <label className='px-2' for='age'>{t("age_plus_18")}</label>

          </div>

          <div className="my-2 ">

            <input
              id="isTermsChecked"
              className="p-2  outline-none border "
              type='checkbox'
              name='isTermsChecked'
              value={isTermsChecked}
              required
              onChange={(e) => {
                setIsTermsChecked(e.target.checked)

              }
              }
            />
            <label className='px-2' for='isTermsChecked'>{t("read_and_agreed_to_the")} <a href="/terms-and-conditions" className='text-blue-600' target={"_blank"}>{t("terms_and_conditions")}</a></label>

          </div>

          <button type='submit' className="p-2 my-2 bg-primary border-primary text-white" >
            {t("continue")}
          </button>
        </form>
      </FormContainer>
    </div>
  )



}

export default ShippingScreen
