import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store'
import './index.css'
import './App.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import Loader from './components/Loader'





const load = <Loader />;

ReactDOM.render(
  <Suspense fallback={load}>
    <Provider store={store} >
      <App />
    </Provider>
  </Suspense>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
