export const CATEGORY_CREATE_REQUEST = 'CATEGORY_CREATE_REQUEST'
export const CATEGORY_CREATE_SUCCESS = 'CATEGORY_CREATE_SUCCESS'
export const CATEGORY_CREATE_FAIL = 'CATEGORY_CREATE_FAIL'
export const CATEGORY_CREATE_RESET = 'CATEGORY_CREATE_RESET'

export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST'
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS'
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL'

export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST'
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS'
export const CATEGORY_DELETE_FAIL = 'CATEGORY_DELETE_FAIL'
