import React from 'react'
import { useTranslation } from 'react-i18next'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/keyboard';

import './../swipper-css.css'

import clubeldologo from '../assets/club-color-logo.png'
import rivegauchelogo from '../assets/rive-rauche-logo.png'
import expertlogo from '../assets/exert-logo.png'
import topgologo from '../assets/topgo.png'

function Partners() {
    const { t } = useTranslation()

    return (
        <div className='max-w-screen-xl mx-auto px-8'>
            <div className="max-w-4xl mx-auto py-6 text-center">
                <h1 className='text-2xl font-bold pb-3 uppercase'>
                    {t("our_partners")}
                </h1>
                <p className='opacity-70'>
                    {t("difatours_maroc_partenariat_ng_travel_garantie_prestations_globales")}
                </p>
            </div>
            {/* <div className='grid grid-cols-2 md:grid-cols-4 gap-4 pt-8'>
                
                    <div>
                        <img src="/boomerang.jpeg" alt="" />
                    </div>
                    <div>
                        <img src="/logo-cappa-club.jpeg" alt="" />
                    </div>
                    <div>
                        <img src="/logo-club-coralia.jpeg" alt="" />
                    </div>
                    <div>
                        <img src="/logo-promo-sejour.jpeg" alt="" />
                    </div>
            </div> */}

            <div className=" pt-8">
                <Swiper
                    spaceBetween={50}
                    slidesPerView={4}
                    modules={[Navigation]}
                    navigation
                    scrollbar={{ draggable: true }}
                    slideNextClass='text-red-600'
                    >
                    <SwiperSlide>
                        <img src="/boomerang.jpeg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/logo-cappa-club.jpeg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/logo-club-coralia.jpeg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/logo-promo-sejour.jpeg" alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={clubeldologo} className='' alt="" />
                    </SwiperSlide>

                    <SwiperSlide>
                        <img src={rivegauchelogo} className='' alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={expertlogo} className='' alt="" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src={topgologo} className='' alt="" />
                    </SwiperSlide>
                </Swiper>
            </div>


        </div>
    )
}

export default Partners