
import * as CAT from "../constants/categoryConstants"

export const categoryCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CAT.CATEGORY_CREATE_REQUEST:
      return { loading: true }
    case CAT.CATEGORY_CREATE_SUCCESS:
      return { loading: false, success: true, category: action.payload }
    case CAT.CATEGORY_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const categoryListReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case CAT.CATEGORY_LIST_REQUEST:
      return { loading: true, categories: [] }
    case CAT.CATEGORY_LIST_SUCCESS:
      return { loading: false, success: true, categories: action.payload.categories }
    case CAT.CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload }

    default:
      return state
  }
}

