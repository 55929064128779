import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import { createProduct, disableProduct, listProductDetails, updateProduct } from '../../actions/productActions'
import { PRODUCT_UPDATE_RESET,PRODUCT_CREATE_RESET } from '../../constants/productConstants'
import Photos from '../../components/Photos'
import Layout from './Layout'
import { toast } from 'react-toastify'

function ProductAddScreen({ match, history }) {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const [images, setImages] = useState([])

  const [name, setName] = useState('')
  const [nameAr, setNameAr] = useState('')
  const [nameFr, setNameFr] = useState('')
  const [nameIt, setNameIt] = useState('')
  const [nameNl, setNameNl] = useState('')

  const [itemProduct, setItemProduct] = useState([]);
  const [nameItem, setNameItem] = useState('')
  const [nameItemAr, setNameItemAr] = useState('')
  const [nameItemFr, setNameItemFr] = useState('')
  const [nameItemIt, setNameItemIt] = useState('')
  const [nameItemNl, setNameItemNl] = useState('')
  const [isHaveItems, setIsHaveItems] = useState(false)
  const [isAddItems, setIsAddItems] = useState(false)
  const [priceItem, setPriceItem] = useState(0)
  const [priceItemEuro, setPriceItemEuro] = useState(0)

  const [price, setPrice] = useState(0)
  const [priceEuro, setPriceEuro] = useState(0)
  const [category, setCategory] = useState('')
  const [subCategory, setSubCategory] = useState('')
  const [ticketType, setTicketType] = useState([])

  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [ticketTypes, setTicketTypes] = useState([])

  const [withticket, setWithticket] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [isPromotion, setIsPromotion] = useState(false)
  const [priceticket, setPriceticket] = useState(0)

  const [countInStock, setCountInStock] = useState(0)
  const [description, setDescription] = useState('')
  const [descriptionFr, setDescriptionFr] = useState('')
  const [descriptionAr, setDescriptionAr] = useState('')
  const [descriptionIt, setDescriptionIt] = useState('')
  const [descriptionNl, setDescriptionNl] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [isDescEn, setIsDescEn] = useState(true)
  const [isDescAr, setIsDescAr] = useState(false)
  const [isDescFr, setIsDescFr] = useState(false)
  const [isDescIt, setIsDescIt] = useState(false)
  const [isDescNl, setIsDescNl] = useState(false)
  const [isAdd, setIsAdd] = useState(false)

  const dispatch = useDispatch()

  const productUpdate = useSelector((state) => state.productUpdate)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate
  const [selectedDays, setSelectedDays] = useState([])

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      // if (successCreate) {
      //   dispatch({ type: PRODUCT_UPDATE_RESET })
      //   history.push('/admin/productlist')
      // } 
    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo])



  useEffect(() => {
    axios.get('/api/categories/').then(res => {
      setCategories(res.data.categories)
    }).catch(err => {
      setCategories([])
    })

  }, [])


  useEffect(() => {
    axios.get(`/api/categories/1/ticket-types/`).then(res => {

      setTicketTypes(res.data.tickettypes)
    }).catch(err => {
      setTicketTypes([])
    })

  }, [])



  useEffect(() => {
    if (category) {
      axios.get(`/api/categories/${category}/sub/`).then(res => {
        console.log(res.data)
        setSubCategories(res.data.subcategories)
      }).catch(err => {
        setSubCategories([])
      })
    }
  }, [category])

  const promo = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100];
  const [promotion, setPromotion] = useState(0);

  const createProd = useSelector((state) => state.productCreate)
  const {
   loadingCreate,
    errorCreate,
    successCreate,
  } = createProd

  useEffect(() => {
      if (successCreate) {
        console.log("hhh good");
        dispatch({ type: PRODUCT_CREATE_RESET })
        history.push('/admin/productlist')
      } 
  }, [dispatch, history, successCreate])

  const submitHandler = async(e) => {
    e.preventDefault()
    
    if(name==="" || nameFr==="" || nameIt==="" || nameNl===""){
      toast.error('Veuillez saisir toutes les traductions du nom du produit.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }else if( category==="" ){
      toast.error('Veuillez entrer la catégorie de ce produit.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    } else if( description===""|| descriptionFr==="" ||  descriptionIt==="" || descriptionNl===""){
      toast.error('Veuillez saisir toutes les traductions de la description du produit.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    } else if(!isHaveItems  && (price==="" || price===0 || priceEuro==="" || priceEuro===0)){
      toast.error('Veuillez vérifier que le prix est correct.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }else if(isHaveItems && itemProduct.length===0){
      toast.error('Vous avez sélectionné Ce produit contient des elements, veuillez ajouter des elements pour ajouter ce produit.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    } else if (images.length===0) {
      toast.error('Veuillez ajouter des images.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    } else{
      setIsAdd(true);
     await dispatch(
        createProduct({
          name,
          nameFr:nameFr,
          nameAr:nameAr,
          nameIt:nameIt,
          nameNl:nameNl,
          price,
          priceEuro: priceEuro,
          category,
          subCategory,
          days: selectedDays,
          withticket,
          priceticket,
          description,
          descriptionAr,
          descriptionFr,
          descriptionIt,
          descriptionNl,
          countInStock,
          startDate: startDate === "" ? null : startDate,
          endDate: endDate === "" ? null : endDate,
          isPromotion: isPromotion,
          promotion: isPromotion ? promotion : 0,
          isHaveItems:isHaveItems,
          items:itemProduct,
        }, images)
      ).then(()=>{
        setIsAdd(false);
      })
    }
  }


  return (
    <>
      <Layout>
        <div className='w-full pb-20'>

          <div className='flex justify-between'>
            <h1 className='text-xl font-600 pb-4'>Ajouter un nouveau Product</h1>
          </div>



          {loadingCreate && <Loader />}
          {errorCreate && <Message variant='danger'>{errorCreate}</Message>}
          
            <form className='relative'>

              <div className='p-4 border rounded-md '>
                <Photos setImages={setImages} product={null} productId={""} />
              </div>

              <div className='border rounded-md mt-3 p-2'>

                <h1 className='text-xl pb-3'>
                  Infos
                </h1>
                <div className='md:flex md:mt-4 my-2'>
                  <div className='md:mr-2 md:flex-1 my-2'>
                    <label className=''>Nom (en)</label>
                    <div className='border rounded-md mt-1'>
                      <input
                        className='p-2 w-full outline-none bg-transparent'
                        type='text'
                        placeholder='Entrez le nom (en)'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className='md:mr-2 md:flex-1 my-2'>
                    <label className=''>Nom (fr)</label>
                    <div className='border rounded-md mt-1'>
                      <input
                        className='p-2 w-full outline-none bg-transparent'
                        type='text'
                        placeholder='Entrez le nom (fr)'
                        value={nameFr}
                        onChange={(e) => setNameFr(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className='md:flex md:mt-4 my-2'>
                  <div className='md:mr-2 md:flex-1 my-2'>
                    <label className=''>Nom (it)</label>
                    <div className='border rounded-md mt-1'>
                      <input
                        className='p-2 w-full outline-none bg-transparent'
                        type='text'
                        placeholder='Entrez le nom (it)'
                        value={nameIt}
                        onChange={(e) => setNameIt(e.target.value)}
                      />
                    </div>
                  </div>
                  {/*  */}
                  <div className='md:mr-2 md:flex-1 my-2'>
                    <label className=''>Nom (nl)</label>
                    <div className='border rounded-md mt-1'>
                      <input
                        className='p-2 w-full outline-none bg-transparent'
                        type='text'
                        placeholder='Entrez le nom (nl)'
                        value={nameNl}
                        onChange={(e) => setNameNl(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className='my-2 md:mt-4'>
                  <label className=''>Catégorie</label>
                  <div className='border required:border-red-600  rounded-md '>
                    <select
                      value={category}
                      onChange={(e) => setCategory(e.target.value)} className='w-full p-2 outline-none bg-transparent' name="category" id="category" required>
                      {/* <option value="" disabled> {category ? category : "Select Category" }   </option> */}
                      {!category && <option value="" disabled>Sélectionner une catégorie</option>}
                      {categories?.map((op, index) => <option key={index} value={op._id}>{op.name}</option>)}
                    </select>
                  </div>
                </div>
                {/* check is have items */}
                <div className='my-2 md:mt-4'>
                  <input
                    id="isItemsChecked"
                    className="p-2  outline-none border "
                    type='checkbox'
                    name='isItemsChecked'
                    value={isHaveItems}
                    required
                    onChange={(e) => {
                      setIsHaveItems(e.target.checked)
                    }
                    }
                  />
                  <label className='px-2' for='isItemsChecked'>Contient des éléments</label>
                </div>
                {
                  isHaveItems?
                  <div className='border rounded-md mt-3 p-2'>
                  {/* info */}
                  <h1 className='text-sm pb-3 flex'>
                    Ajouter des Éléments <span className='mx-3 cursor-pointer text-primaryad' onClick={()=>setIsAddItems(true)}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </span>
                  </h1>
                  <div>
                    {
                      itemProduct.length!=0?
                          <div class="py-2 inline-block min-w-full">
                            <div class="overflow-hiddens">
                              <table class="min-w-full ">
                                <thead class="bg-white border-b">
                                  <tr>
                                    <th
                                      scope="col"
                                      class="text-sm font-medium text-gray-900 px-6 py-2 text-left"
                                    >
                                      Élément
                                    </th>
                                    <th
                                      scope="col"
                                      class="text-sm font-medium text-gray-900 px-6 py-2 text-left"
                                    >
                                      Prix (Dhs)
                                    </th>
                                    <th
                                      scope="col"
                                      class="text-sm font-medium text-gray-900 px-6 py-2 text-left"
                                    >
                                      Prix (Euro)
                                    </th>
                                    <th
                                      scope="col"
                                      class="text-sm font-medium text-gray-900 px-6 py-2 text-left"
                                    >
                                      Operation
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    itemProduct?.map((item)=>(
                                      <tr className='bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"'>
                                          <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                              {item.name}
                                          </td>
                                          <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                              {item.price}
                                          </td>
                                          <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                              {item.price_euro}
                                          </td>

                                          <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                            <svg onClick={()=>{
                                              const updatedItems = itemProduct.filter(itm => itm.name !== item.name);
                                              // Update the state with the new array
                                              setItemProduct(updatedItems);
                                            }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                                              <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                          </td>
                                          
                                          
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                            </div>
                          </div>:null
                      }
                      
                    </div>
                </div>:null

                }
                
                {
                  !isHaveItems?
                    <div className='md:flex md:mt-4 my-2'>
                      <div className='md:mr-2 md:flex-1 my-2'>
                        <label className=''>Prix (Dhs)</label>
                        <div className='border rounded-md'>
                          <input
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            className='p-2 w-full outline-none bg-transparent'
                            type='text'
                            placeholder='Entrez le prix (Dhs)'
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                          />
                        </div>
                      </div>
                      {/*  */}
                      <div className='md:mr-2 md:flex-1 my-2'>
                        <label className=''>Prix (Euro)</label>
                        <div className='border rounded-md'>
                          <input
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            className='p-2 w-full outline-none bg-transparent'
                            type='text'
                            placeholder='Entrez le prix (Euro)'
                            value={priceEuro}
                            onChange={(e) => setPriceEuro(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>:null

                }

                
                <div className='my-2 md:mt-4'>
                  <label className=' '>Quantité</label>
                  <div className='border rounded-md'>
                    <input
                      type='text'
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      minLength="3"
                      maxLength={3}
                      className='p-2 w-full outline-none bg-transparent'
                      placeholder='Entrez countInStock'
                      value={countInStock}
                      onChange={(e) => setCountInStock(e.target.value)}
                    />
                  </div>
                </div>
                <div className='my-2 md:mt-4'>
                  <input
                    id="isPromotionChecked"
                    className="p-2  outline-none border "
                    type='checkbox'
                    name='isPromotionChecked'
                    value={isPromotion}
                    required
                    onChange={(e) => {
                      setIsPromotion(e.target.checked)

                    }
                    }
                  />
                  <label className='px-2' for='isPromotionChecked'>Promotion</label>
                </div>

                {
                  isPromotion ?
                    <>
                      <div className='border required:border-red-600  rounded-md '>
                        <select
                          value={promotion}
                          onChange={(e) => setPromotion(e.target.value)} className='w-full p-2 outline-none bg-transparent' name="promotion" id="promotion" required>
                          <option  >Sélectionner une promotion </option>
                          {promo?.map((prom, index) => <option key={prom} value={prom}>- {prom} %</option>)}
                        </select>
                      </div>
                    </>
                    : null
                }

                

                <div className='md:flex my-2 md:mt-4'>



                  <div className='md:mr-2  md:flex-1 my-2'>
                    <label className=' '>Date de début</label>
                    <div className='border rounded-md'>
                      <input
                        type='date'

                        className='p-2 w-full outline-none bg-transparent'
                        placeholder='Entrez la date de début'
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        min={new Date().toISOString().split('T')[0]}
                      />
                    </div>
                  </div>

                  <div className='md:ml-2 md:flex-1 my-2'>
                    <label className=' '>Date de fin</label>
                    <div className='border rounded-md'>
                      <input
                        type='date'
                        className='p-2 w-full outline-none bg-transparent'
                        placeholder='Entrez la Date de fin'
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        min={new Date().toISOString().split('T')[0]}
                      />
                    </div>
                  </div>

                </div>
              </div>

              <div className='p-2 mt-3 border rounded-md md:mt-4'>
                <div className=' my-2'>
                  <div className='flex flex-wrap'>
                    <label onClick={() => {
                      setIsDescEn(true);
                      setIsDescFr(false);
                      setIsDescAr(false);
                      setIsDescIt(false);
                      setIsDescNl(false);
                    }} className={`mx-2 cursor-pointer ${isDescEn ? 'border-b-4' : ''}`}>Description (En)</label>
                    <label onClick={() => {
                      setIsDescEn(false);
                      setIsDescFr(true);
                      setIsDescAr(false);
                      setIsDescIt(false);
                      setIsDescNl(false);
                    }} className={`mx-2 cursor-pointer ${isDescFr ? 'border-b-4' : ''}`}>Description (Fr)</label>
                    {/* <label onClick={() => {
                      setIsDescEn(false);
                      setIsDescFr(false);
                      setIsDescAr(true);
                      setIsDescIt(false);
                      setIsDescNl(false);
                    }} className={`mx-2 cursor-pointer ${isDescAr ? 'border-b-4' : ''}`}>Description (Ar)</label> */}
                    <label onClick={() => {
                      setIsDescEn(false);
                      setIsDescFr(false);
                      setIsDescAr(false);
                      setIsDescIt(true);
                      setIsDescNl(false);
                    }} className={`mx-2 cursor-pointer ${isDescIt ? 'border-b-4' : ''}`}>Description (It)</label>
                    <label onClick={() => {
                      setIsDescEn(false);
                      setIsDescFr(false);
                      setIsDescAr(false);
                      setIsDescIt(false);
                      setIsDescNl(true);
                    }} className={`mx-2 cursor-pointer ${isDescNl ? 'border-b-4' : ''}`}>Description (Nl)</label>
                  </div>

                  <div className='border rounded-md mt-2'>
                    {/* desc en */}
                    {
                      isDescEn ?
                        <textarea
                          className='p-2 w-full outline-none bg-transparent'
                          type='text'
                          rows={10}
                          placeholder='Entrez la description (En)'
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        /> : <></>
                    }

                    {/* desc ar */}
                    {
                      isDescAr ? <textarea
                        className='p-2 w-full outline-none bg-transparent'
                        type='text'
                        rows={10}
                        placeholder='Entrez la description (Ar)'
                        value={descriptionAr}
                        onChange={(e) => setDescriptionAr(e.target.value)}
                      /> : <></>
                    }
                    {/* desc fr */}
                    {
                      isDescFr ? <textarea
                        className='p-2 w-full outline-none bg-transparent'
                        type='text'
                        rows={10}
                        placeholder='Entrez la description (Fr)'
                        value={descriptionFr}
                        onChange={(e) => setDescriptionFr(e.target.value)}
                      /> : <></>
                    }
                    {/* desc it */}
                    {
                      isDescIt ? <textarea
                        className='p-2 w-full outline-none bg-transparent'
                        type='text'
                        rows={10}
                        placeholder='Entrez la description (It)'
                        value={descriptionIt}
                        onChange={(e) => setDescriptionIt(e.target.value)}
                      /> : <></>
                    }
                    {/* desc Nl */}
                    {
                      isDescNl ? <textarea
                        className='p-2 w-full outline-none bg-transparent'
                        type='text'
                        rows={10}
                        placeholder='Entrez la description (Nl)'
                        value={descriptionNl}
                        onChange={(e) => setDescriptionNl(e.target.value)}
                      /> : <></>
                    }
                  </div>

                </div>
              </div>


              <div className='fixed border-t bottom-0 w-4/5 right-0  bg-white'>
                <div className='flex p-2 justify-between items-center'>
                  <h1 className='text-2xl'>
                    {name}
                  </h1>
                  <button onClick={submitHandler} className="rounded-md py-2 px-8 text-white  bg-primary inline-flex">
                    {
                        isAdd?
                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>   :null
                    }  Ajouter
                  </button>
                </div>
              </div>
            </form>
        </div >
        {isAddItems ? (
          <>
              <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
                  <div className="bg-white p-6 rounded shadow-md">
                      <h3 className="text-lg font-bold mb-4">Ajouter des Éléments</h3>
                      {/* <p className="mb-4">Voulez-vous confirmer votre choix ?</p> */}
                      <div className='mb-3'>
                          <div className='md:flex md:mt-4 my-1'>
                            <div className='md:mr-2 md:flex-1 my-1'>
                              <label className=''>Nom Élément (en)</label>
                              <div className='border rounded-md mt-1'>
                                <input
                                  className='p-2 w-full outline-none bg-transparent'
                                  type='text'
                                  placeholder="Entrez le nom de l'élément (en)"
                                  value={nameItem}
                                  onChange={(e) => setNameItem(e.target.value)}
                                />
                              </div>
                            </div>
                            {/*  */}
                            <div className='md:mr-2 md:flex-1 my-1'>
                              <label className=''>Nom Élément (fr)</label>
                              <div className='border rounded-md mt-1'>
                                <input
                                  className='p-2 w-full outline-none bg-transparent'
                                  type='text'
                                  placeholder="Entrez le nom de l'élément (fr)"
                                  value={nameItemFr}
                                  onChange={(e) => setNameItemFr(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className='md:flex md:mt-4 my-1'>
                            <div className='md:mr-2 md:flex-1 my-1'>
                              <label className=''>Nom de l'élément (it)</label>
                              <div className='border rounded-md mt-1'>
                                <input
                                  className='p-2 w-full outline-none bg-transparent'
                                  type='text'
                                  placeholder="Entrez le nom de l'élément (it)"
                                  value={nameItemIt}
                                  onChange={(e) => setNameItemIt(e.target.value)}
                                />
                              </div>
                            </div>
                            {/*  */}
                            <div className='md:mr-2 md:flex-1 my-1'>
                              <label className=''>Nom de l'élément(nl)</label>
                              <div className='border rounded-md mt-1'>
                                <input
                                  className='p-2 w-full outline-none bg-transparent'
                                  type='text'
                                  placeholder="Entrez le nom de l'élément (nl)"
                                  value={nameItemNl}
                                  onChange={(e) => setNameItemNl(e.target.value)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className='md:mr-2 md:flex-1 my-1'>
                            <label className=''>Prix de l'élément (Dhs)</label>
                            <div className='border rounded-md'>
                              <input
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                className='p-2 w-full outline-none bg-transparent'
                                type='text'
                                placeholder="Entrez le Prix de l'élément (Dhs)"
                                value={priceItem}
                                onChange={(e) => setPriceItem(e.target.value)}
                              />
                            </div>
                          </div>
                          {/*  */}
                          <div className='md:mr-2 md:flex-1 my-1'>
                            <label className=''>Prix de l'élément (Euro)</label>
                            <div className='border rounded-md'>
                              <input
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                                className='p-2 w-full outline-none bg-transparent'
                                type='text'
                                placeholder="Entrez le Prix de l'élément (Euro)"
                                value={priceItemEuro}
                                onChange={(e) => setPriceItemEuro(e.target.value)}
                              />
                            </div>
                          </div>
                        
                      </div>

                      <div className="flex justify-end">
                          <button onClick={()=>{
                            if(nameItem==="" || nameItemFr === "" || nameItemIt === "" || nameItemNl=== "" || priceItem==="" || priceItemEuro==="" ){
                              toast.error('Veuillez saisir toutes les informations pour ajouter un nouvel élément', {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                });
                            }else if(priceItem===0 || priceItemEuro===0 ){
                              toast.error('Veuillez saisir le prix correct pour ajouter un nouvel élément', {
                                position: "top-center",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                });
                            }else{
                              itemProduct.push({
                                "name":nameItem,
                                "name_fr":nameItemFr,
                                "name_it":nameItemIt,
                                "name_nl":nameItemNl,
                                "name_ar":nameItemAr,
                                "price":priceItem,
                                "price_euro":priceItemEuro,
                              });
                              setIsAddItems(false);
                              setNameItem("");
                              setNameItemIt("");
                              setNameItemAr("");
                              setNameItemFr("");
                              setNameItemNl("");
                              setPriceItem(0);
                              setPriceItemEuro(0);
                            }
                          }} className=" bg-blue-600  text-white font-bold py-2 px-4 rounded mr-2" >Ajouter</button>
                          <button className="bg-red-600  text-white font-bold py-2 px-4 rounded" onClick={() =>{
                            setIsAddItems(false);
                            setNameItem("");
                            setNameItemIt("");
                            setNameItemAr("");
                            setNameItemFr("");
                            setNameItemNl("");
                            setPriceItem(0);
                            setPriceItemEuro(0);
                          }} > Annuler </button>
                      </div>
                  </div>
              </div>
          </>
      ) : null}
      </Layout >
    </>
  )
}

export default ProductAddScreen