import React from 'react'
import generatedHash from '../utils/generateHash'


function PaymentForm({order,title}) {
  
    const _url = `https://payment.travellinkmorocco.com/?order=${order._id}`

    const dataForm ={
        clientid:"600000155",
        storetype:"3d_pay_hosting",
        okUrl:`https://payment.travellinkmorocco.com/okfail.php`,
        rnd:"453",
        hashAlgorithm:"ver3",
        encoding:"UTF-8",
        TranType:"PreAuth",
        currency:"504",
        amount:order?.totalPrice,
        CallbackResponse:"true",
        BillToCity:"",
        email:order?.shippingAddress?.email,
        tel:order?.shippingAddress?.phone,
        BillToStateProv:"",
        BillToCountry:"",
        BillToStreet1:"",
        BillToTelVoice:"",
        shopurl:`${_url}`,
        failUrl:`https://payment.travellinkmorocco.com/okfail.php`,
        callbackUrl:`https://payment.travellinkmorocco.com/callback.php`,
        BillToName:`${order?.shippingAddress?.firstName} ${order?.shippingAddress?.lastName}`,
        lang:"en",
        // oid:new Date().getTime(),
        // produ
        oid:order._id
    }
    console.log(dataForm);
    
    return (
        <form method='POST' action='https://payment.cmi.co.ma/fim/est3Dgate'  >
        {/* <form onSubmit={prePayment}> */}
            {
                Object.keys(dataForm).map((key) => {
                   return (
                        <div key={key}>
                            <input type={"hidden"} name={key} value={dataForm[key]} /> 
                        </div>
                        // <li key={key + index}>{key}:{items[key]}</li>
                    )
                })
            }            
        
            <input type="hidden" name="hash" value={generatedHash(dataForm)} />  
        <button className='p-2 bg-red-600 text-white w-full' type='submit'>{title}</button>
    </form>
    )
}

export default PaymentForm