export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

export const PRODUCT_LIST_PROMOTION_REQUEST = 'PRODUCT_LIST_PROMOTION_REQUEST'
export const PRODUCT_LIST_PROMOTION_SUCCESS = 'PRODUCT_LIST_PROMOTION_SUCCESS'
export const PRODUCT_LIST_PROMOTION_FAIL = 'PRODUCT_LIST_PROMOTION_FAIL'

export const PRODUCT_SEARCH_REQUEST = 'PRODUCT_SEARCH_REQUEST'
export const PRODUCT_SEARCH_SUCCESS = 'PRODUCT_SEARCH_SUCCESS'
export const PRODUCT_SEARCH_FAIL = 'PRODUCT_SEARCH_FAIL'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL'

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL'
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET'

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL'
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET'

export const PRODUCT_CREATE_REVIEW_REQUEST = 'PRODUCT_CREATE_REVIEW_REQUEST'
export const PRODUCT_CREATE_REVIEW_SUCCESS = 'PRODUCT_CREATE_REVIEW_SUCCESS'
export const PRODUCT_CREATE_REVIEW_FAIL = 'PRODUCT_CREATE_REVIEW_FAIL'
export const PRODUCT_CREATE_REVIEW_RESET = 'PRODUCT_CREATE_REVIEW_RESET'

export const PRODUCT_TOP_REQUEST = 'PRODUCT_TOP_REQUEST'
export const PRODUCT_TOP_SUCCESS = 'PRODUCT_TOP_SUCCESS'
export const PRODUCT_TOP_FAIL = 'PRODUCT_TOP_FAIL'


export const PRODUCT_LIST_COUNT_REQUEST = "PRODUCT_LIST_COUNT_REQUEST"
export const PRODUCT_LIST_COUNT_SUCCESS = "PRODUCT_LIST_COUNT_SUCCESS"
export const PRODUCT_LIST_COUNT_FAIL = "PRODUCT_LIST_COUNT_FAIL"


export const PRODUCT_REVIEWS_REQUEST = "PRODUCT_REVIEWS_REQUEST"
export const PRODUCT_REVIEWS_SUCCESS = "PRODUCT_REVIEWS_SUCCESS"
export const PRODUCT_REVIEWS_FAIL = "PRODUCT_REVIEWS_FAIL"

export const PRODUCT_LIST_REVIEWS_REQUEST = "PRODUCT_LIST_REVIEWS_REQUEST"
export const PRODUCT_LIST_REVIEWS_SUCCESS = "PRODUCT_LIST_REVIEWS_SUCCESS"
export const PRODUCT_LIST_REVIEWS_FAIL = "PRODUCT_LIST_REVIEWS_FAIL"

export const PRODUCT_DELETE_REVIEWS_REQUEST = "PRODUCT_DELETE_REVIEWS_REQUEST"
export const PRODUCT_DELETE_REVIEWS_SUCCESS = "PRODUCT_DELETE_REVIEWS_SUCCESS"
export const PRODUCT_DELETE_REVIEWS_FAIL = "PRODUCT_DELETE_REVIEWS_FAIL"

