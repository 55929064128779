import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  // 
  PRODUCT_LIST_PROMOTION_REQUEST,
  PRODUCT_LIST_PROMOTION_SUCCESS,
  PRODUCT_LIST_PROMOTION_FAIL,
  // 
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAIL,
  PRODUCT_CREATE_RESET,
  PRODUCT_CREATE_FAIL,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_RESET,
  PRODUCT_CREATE_REVIEW_REQUEST,
  PRODUCT_CREATE_REVIEW_SUCCESS,
  PRODUCT_CREATE_REVIEW_FAIL,
  PRODUCT_CREATE_REVIEW_RESET,
  PRODUCT_TOP_REQUEST,
  PRODUCT_TOP_SUCCESS,
  PRODUCT_TOP_FAIL,
  PRODUCT_LIST_COUNT_REQUEST,
  PRODUCT_LIST_COUNT_SUCCESS,
  PRODUCT_LIST_COUNT_FAIL,
  PRODUCT_SEARCH_REQUEST,
  PRODUCT_SEARCH_FAIL,
  PRODUCT_SEARCH_SUCCESS,
  PRODUCT_REVIEWS_REQUEST,
  PRODUCT_REVIEWS_SUCCESS,
  PRODUCT_REVIEWS_FAIL,

  PRODUCT_LIST_REVIEWS_REQUEST,
  PRODUCT_LIST_REVIEWS_SUCCESS,
  PRODUCT_LIST_REVIEWS_FAIL,

  PRODUCT_DELETE_REVIEWS_REQUEST,
  PRODUCT_DELETE_REVIEWS_SUCCESS,
  PRODUCT_DELETE_REVIEWS_FAIL,
} from '../constants/productConstants'

export const deleteProductReviewsReducer = (
  state = { state: {} },
  action
) => {
  switch (action.type) {
    case PRODUCT_DELETE_REVIEWS_REQUEST:
      return { ...state, loadingD: true }
    case PRODUCT_DELETE_REVIEWS_SUCCESS:
      return { loadingD: false, }
    case PRODUCT_DELETE_REVIEWS_FAIL:
      return { loadingD: false, errorD: action.payload }
    default:
      return state
  }
}


export const productReviewsReducer = (
  state = { state: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_REVIEWS_REQUEST:
      return { ...state, loading: true }
    case PRODUCT_LIST_REVIEWS_SUCCESS:

      return { loading: false, reviews: action.payload }
    case PRODUCT_LIST_REVIEWS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productStatsReducer = (state = { all: 0, active: 0 }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_COUNT_REQUEST:
      return { loading: true, all: 0, active: 0 }
    case PRODUCT_LIST_COUNT_SUCCESS:
      return {
        loading: false,
        all: action.payload.all,
        active: action.payload.active
      }
    case PRODUCT_LIST_COUNT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productListReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { loading: true, products: [] }
    case PRODUCT_LIST_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productListPromotionReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_LIST_PROMOTION_REQUEST:
      return { loading: true, products: [] }
    case PRODUCT_LIST_PROMOTION_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case PRODUCT_LIST_PROMOTION_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}


export const productSearchReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_SEARCH_REQUEST:
      return { loading: true, products: [] }
    case PRODUCT_SEARCH_SUCCESS:
      return {
        loading: false,
        products: action.payload.products,
        pages: action.payload.pages,
        page: action.payload.page,
      }
    case PRODUCT_SEARCH_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productDetailsReducer = (
  state = { product: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { ...state, loading: true }
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload }
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_DELETE_REQUEST:
      return { loading: true }
    case PRODUCT_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REQUEST:
      return { loadingCreate: true }
    case PRODUCT_CREATE_SUCCESS:
      return { loadingCreate: false, successCreate: true, productCreate: action.payload }
    case PRODUCT_CREATE_FAIL:
      return { loadingCreate: false, errorCreate: action.payload }
    case PRODUCT_CREATE_RESET:
      return {}
    default:
      return state
  }
}

export const productUpdateReducer = (state = { product: {} }, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true }
    case PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true, product: action.payload }
    case PRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    case PRODUCT_UPDATE_RESET:
      return { product: {} }
    default:
      return state
  }
}

export const productReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REVIEW_REQUEST:
      return { loading: true }
    case PRODUCT_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload }
    case PRODUCT_CREATE_REVIEW_RESET:
      return {}
    default:
      return state
  }
}

export const productTopRatedReducer = (state = { products: [] }, action) => {
  switch (action.type) {
    case PRODUCT_TOP_REQUEST:
      return { loading: true, products: [] }
    case PRODUCT_TOP_SUCCESS:
      return { loading: false, products: action.payload }
    case PRODUCT_TOP_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}



export const productReviewAddReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_REVIEWS_REQUEST:
      return { loading: true }
    case PRODUCT_REVIEWS_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_REVIEWS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}