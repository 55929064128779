import React from 'react'

const Message = ({ variant, children }) => {
  return <div className={`p-2 w-full  ${variant==="info" ? 'bg-yellow-300':variant==="danger"?"bg-red-600":variant==="success"?"bg-green-600":""}`}>{children}</div>
}

Message.defaultProps = {
  variant: 'bg-gray-100 ',
}

export default Message
