import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { searchProducts } from '../actions/productActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Product from '../components/Product'
import Paginate from '../components/Paginate'
import { useTranslation } from 'react-i18next'


const Header = () => {
    return (
        <div className='bg-secondary bg-opacity-25 rounded-b-3xl flex flex-col justify-end pb-10 items-center h-32 w-full'>
            {/* <h1 className='text-3xl md:text-4xl text-center max-w-xl '>
          On voyage pour changer, non de lieu, mais d'idées
        </h1> */}
        </div>
    )
}

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function SearchScreen({ match, history }) {
    const dispatch = useDispatch()
    const location = useLocation()

    const { t } = useTranslation();

    const pageNumber = match.params.pageNumber || 1

    // const keyword = match.params.keyword || ''
    const keyword = location.search ? location.search.split("?")[1].split("=")[0] : ""
    // console.log(keyword)
    const search = match.params.search || ''

    const type = match.params.type || ''

    const { loading, error, products, page, pages } = useSelector((state) => state.productSearch)


    useEffect(() => {
        dispatch(searchProducts("", keyword, pageNumber, search))
    }, [dispatch, pageNumber, search, keyword])


    return (
        <div className=" min-h-screen ">

            <Header />

            <div className='pb-20 xl:px-3 px-8 max-w-screen-xl mx-auto pt-20'>



                {loading ? (
                    <Loader />
                ) : error ? (
                    <Message variant='danger'>{t(error)}</Message>
                ) : (
                    <>
                        {products.length > 0 ?
                            <div className="grid md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 grid-cols-1 lg:gap-x-6 gap-x-4 lg:gap-y-2 gap-y-1  lg:col-span-3">
                                {products.map((product) => (
                                    <div className="group cursor-pointer fade-in text-sm lg:-20 duration-300" key={product._id} dir='ltr'  >
                                        <Product product={product} />
                                    </div>
                                ))}
                            </div>
                            :
                            <div className='flex flex-col items-center justify-center  h-64 '>
                                <img src="/icons/icon-camping.svg" alt="" srcset="" />
                                <div>
                                    {t("search_no_results")}
                                </div>
                            </div>
                        }

                        <Paginate
                            pages={pages}
                            page={page}
                            keyword={keyword}
                            type={type}
                        />



                        <div>
                            {/* <h1>Here put some content</h1> */}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default SearchScreen