import React, { useEffect } from 'react'
import SideBar from '../../components/SideBar'
import { useDispatch, useSelector } from 'react-redux'

function MessageScreen({ history }) {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const dispatch = useDispatch()

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {

    } else {
      history.push('/login')
    }
  }, [dispatch, history, userInfo])

  return (
    <div className="admin-screen">
      <div className="flex ">
        <div className='w-1/5 '>
          <SideBar />
        </div>

        <div className="px-2 md:w-3/5 relative w-full">
          <h1 className='text-xl pb-1 '>Messages </h1>
          <hr />

        </div>
      </div>
    </div>
  )
}

export default MessageScreen