import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from '../actions/userActions'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

function SideBar() {
  const [show, setShow] = useState(false)

  const dispatch = useDispatch()
  const { pathname } = useLocation();

  const handleLogout = () => {
    console.log(pathname);
    dispatch(logout())
  }

  return (
    <div className=" ">
      <ul className=" ">
        <div className="flex min-h-screen    flex-col justify-between">
          <div>
            <div className="mx-auto pb-6 mr-3">
              <a href="/" className="px-3 relative pr-3 flex mx-auto my-auto text-center">
                <img className="w-12 block sm:hidden" src="/logo_icon.png" alt="" />
                <img className="w-32 hidden md:block" src="/logo.png" alt="" />
                {/* <span className="pl-3 hidden md:block mt-5 font-bold"> Difa Tours</span> */}
              </a>
            </div>

            <Link to="/admin/">
              <li
                className={`cursor-pointer ${pathname === "/admin/" && "rounded-l-full bg-[#F5F5F5] text-primaryad font-bold"} p-2 mb-1 flex items-center`}>
                <span className="text-secondary">
                  <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 `} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                  </svg>
                </span>
                <span className="pl-3 hidden md:block"> Tableau de bord</span>
              </li>
            </Link>

            <Link to="/admin/config">
              <li
                className={`cursor-pointer ${pathname === "/admin/config" &&
                  "rounded-l-full bg-[#F5F5F5] text-primaryad "
                  } p-2 mb-1 flex items-center`}
              >
                <span className="text-secondary">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                </span>
                <span className="pl-3 hidden md:block">Configuration</span>
              </li>
            </Link>

            <Link to="/admin/productlist">
              <li
                className={`cursor-pointer ${(pathname === "/admin/productlist" || pathname.includes("/admin/product")) &&
                  "rounded-l-full bg-[#F5F5F5] text-primaryad "
                  } p-2 mb-1 flex items-center`}>
                <span className="text-secondary">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 " fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                  </svg>
                </span>
                <span className="px-3 hidden md:block ">Produits</span>
              </li>
            </Link>
            <Link to="/admin/orderlist">
              <li
                className={`cursor-pointer ${pathname === "/admin/orderlist" &&
                  "rounded-l-full bg-[#F5F5F5] text-primaryad "
                  } p-2 mb-1 flex items-center`}
              >
                <span className="text-secondary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6 "
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </span>
                <span className="pl-3 hidden md:block">Commandes</span>
              </li>
            </Link>

            <Link to="/admin/subscribers">
              <li
                className={`cursor-pointer ${pathname === "/admin/subscribers" &&
                  "rounded-l-full bg-[#F5F5F5] text-primaryad "
                  } p-2 mb-1 flex items-center`}
              >
                <span className="text-secondary">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
                  </svg>
                </span>

                <span className="pl-3 hidden md:block">Abonnés</span>
              </li>
            </Link>
            <Link to="/admin/pickups">
              <li
                className={`cursor-pointer ${pathname === "/admin/pickups" &&
                  "rounded-l-full bg-[#F5F5F5] text-primaryad "
                  } p-2 mb-1 flex items-center`}
              >
                <span className="text-secondary">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                  </svg>

                </span>

                <span className="pl-3 hidden md:block">Points de ramassage</span>
              </li>
            </Link>
          </div>
          <div className="pb-6  ">
            <button onClick={handleLogout} className="w-full rounded-md p-2 ">
              <div className="flex items-center">
                <span className="text-secondary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-6 w-6 "
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                    />
                  </svg>
                </span>
                <div className="pl-3 hidden md:block">Déconnexion</div>
              </div>
            </button>
          </div>
        </div>
      </ul>
    </div>



    // <div className='border-r '>
    //   <ul className=" fixed ">
    //     <div className='flex h-[99vh]   flex-col justify-between w-fit'>

    //       <div>
    //         {/* <div className='mx-auto pb-6'>
    //           <div className='px-1 relative'>
    //             <div className={`${show ? "rounded-t-md border-t border-b border-l border-r" : "rounded-md border"} bg-white p-2 `}>
    //               <button className='flex  justify-between w-full items-center' onClick={() => setShow(!show)}>
    //                 <div className='text-xl flex items-center '>
    //                   <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fill-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    //                     <path strokeLinecap="round" strokeLinejoin="round" d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
    //                   </svg>
    //                   <span className='pl-1 hidden md:block'>Gestion Admin </span>
    //                 </div>

    //                 <div>
    //                   <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    //                     <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    //                   </svg>
    //                 </div>
    //               </button>

    //             </div>

    //             <div className={`${show ? "absolute" : "hidden"} z-50 p-2 bg-white border-l border-b border-r rounded-b-md left-1 right-1 `}>
    //               <div className='text-xl flex items-center '>
    //                 <img src="/logo_icon.png" className='w-6 ' alt="" srcset="" />
    //                 <a href='/' className='pl-1 hidden md:block'>
    //                   DifaTours
    //                 </a>
    //               </div>
    //             </div>


    //           </div>
    //         </div> */}

    //         <div class="mx-auto pb-6">
    //           <a href="/" class="px-3 relative pr-3">

    //             <img class="w-24" src="/logo_icon.png" alt="" />
    //           </a>
    //         </div>

    //         <Link to="/admin/">
    //           <li className={`cursor-pointer ${pathname === "/admin/" &&
    //             "rounded-l-full md:bg-[#F5F5F5] text-black "
    //             } overflow-hidden  p-2 mb-1 flex items-center `}>
    //             <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 fill-primary ${pathname === "/admin/" ? 'rounded-l-full bg-[#F5F5F5]' : ''}`} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    //               <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
    //             </svg>
    //             <span className={`pl-3 hidden md:block ${pathname === "/admin/" ? 'text-black' : 'text-white'}`}>
    //               Tableau de bord
    //             </span>
    //           </li>
    //         </Link>
    //         <Link to="/admin/config">
    //           {/* hover:bg-gray-50  */}
    //           <li className={`cursor-pointer ${pathname === "/admin/config" &&
    //             "rounded-l-full bg-[#F5F5F5] text-black "
    //             } overflow-hidden  p-2 mb-1 flex items-center `}>
    //             <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fill-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    //               <path strokeLinecap="round" strokeLinejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
    //               <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    //             </svg>
    //             <span className={`pl-3 hidden md:block ${pathname === "/admin/config" ? 'text-black' : 'text-white'}`}>
    //               Configuration
    //             </span>
    //           </li>
    //         </Link>
    //         <Link to="/admin/productlist">

    //           <li className={`cursor-pointer ${pathname === "/admin/productlist" &&
    //             "rounded-l-full bg-[#F5F5F5] text-black "
    //             } overflow-hidden  p-2 mb-1 flex items-center `}>
    //             <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fill-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    //               <path strokeLinecap="round" strokeLinejoin="round" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
    //             </svg>
    //             <span className={`pl-3 hidden md:block ${pathname === "/admin/productlist" ? 'text-black' : 'text-white'}`}>
    //               Produits
    //             </span>
    //           </li>
    //         </Link>

    //         <Link to="/admin/orderlist">
    //           <li className={`cursor-pointer ${pathname === "/admin/orderlist" &&
    //             "rounded-l-full bg-[#F5F5F5] text-black "
    //             } overflow-hidden  p-2 mb-1 flex items-center `}>
    //             <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fill-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    //               <path strokeLinecap="round" strokeLinejoin="round" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    //             </svg>
    //             <span className={`pl-3 hidden md:block ${pathname === "/admin/orderlist" ? 'text-black' : 'text-white'}`}>
    //               Commandes
    //             </span>
    //           </li>
    //         </Link>

    //         <Link to="/admin/subscribers">
    //           <li className={`cursor-pointer ${pathname === "/admin/subscribers" &&
    //             "rounded-l-full bg-[#F5F5F5] text-black "
    //             } overflow-hidden  p-2 mb-1 flex items-center `}>
    //             <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fill-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
    //               <path strokeLinecap="round" strokeLinejoin="round" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
    //             </svg>
    //             <span className={`pl-3 hidden md:block ${pathname === "/admin/subscribers" ? 'text-black' : 'text-white'}`}>
    //               Abonnés
    //             </span>
    //           </li>
    //         </Link>
    //       </div>
    //       <div className='pb-6  '>
    //         <button onClick={handleLogout} className='w-full rounded-md p-2 border'>
    //           <div className='flex items-center'>

    //             <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-6 w-6 fill-primary" >
    //               <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
    //             </svg>

    //             <div className='pl-3 hidden md:block text-white'>
    //               Déconnexion
    //             </div>
    //           </div>


    //         </button>
    //       </div>

    //     </div>
    //   </ul>

    // </div>


  )
}

export default SideBar
